import { defineStore } from 'pinia'

import usePathApi from '@/stores/api/pathApi'
import usePlayerApi from '@/stores/api/playerApi'

// import { useMarkStore } from '@/stores/markStore';

import { Path, Mark, Player, Notice } from '@/stores/interfaces'

import { useAuthStore } from '@/stores/authStore'

import { useRoute } from 'vue-router'

import { useGlobalStore } from '@/stores/globalStore'


export const usePathStore = defineStore({

  id: 'pathStore',

  /* --------------------------------- STATE -------------------------------- */

  state: () => ({
    route: useRoute(),
    globalStore: useGlobalStore(),
    dirtyOne: false,
    dirtyAll: false,
    trails: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Path[],
      pagination: {
        total: 0
      }
    },
    trail: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Path,
    },
    places: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Mark[],
      pagination: {
        total: 0
      }
    },
    place: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Mark,
    },
    steps: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Mark[],
      pagination: {
        total: 0
      }
    },
    step: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Mark,
    },
    links: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Mark[],
      pagination: {
        total: 0
      }
    },
    link: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Mark,
    },
    pois: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Mark[],
      pagination: {
        total: 0
      }
    },
    poi: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Mark,
    },
    managers: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Player[],
      pagination: {
        total: 0
      }
    },
    manager: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Player,
    },
    walkers: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Player[],
      pagination: {
        total: 0
      }
    },
    walker: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Player,
    },
    advices: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Notice[],
      pagination: {
        total: 0
      }
    },
    advice: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Notice,
    },
    reports: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Notice[],
      pagination: {
        total: 0
      }
    },
    report: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Notice,
    },
  }),

  /* --------------------------------- GETTERS -------------------------------- */

  getters: {},

  /* --------------------------------- ACTIONS -------------------------------- */

  actions: {

    /* -------------------------------------------------------------------------- */

    getContextDistincts(currentRole: string, selector: string, selectorIndex?: string): Promise<string[]> {
      console.log('getContextDistincts ' + currentRole)
      const authStore = useAuthStore()
      // if (!authStore.authenticated) return;
      if (
        authStore.isSuperAdmin ||
        authStore.isAdmin
      ) {
        return this.getDistincts(currentRole, selector, selectorIndex)
      } else {
        return this.getDistinctsOf(authStore.role, authStore.id, currentRole, selector, selectorIndex)
      }
    },

    /* -------------------------------------------------------------------------- */

    getDistinctsOf(modelRole: string, modelId: any, currentRole: string, selector: string, selectorIndex?: string): Promise<string[]> {
      return new Promise<string[]>((resolve) => {
        console.log('getDistinctsOf ' + currentRole + ' @ ' + selector + ' of ' + modelRole + '#' + modelId)
        // reset before querying

        const { getDistinctsOf } = usePathApi()
        getDistinctsOf(modelRole, modelId, currentRole, selector, selectorIndex)
          .then((response) => resolve(response), (error) => {
            this.globalStore.handleStoreError(error, 'getDistinctsOf')
          })
      })
    },

    /* -------------------------------------------------------------------------- */

    getDistincts(currentRole: string, selector: string, selectorIndex?: string): Promise<string[]> {
      return new Promise<string[]>((resolve) => {
        console.log('getDistincts ' + currentRole + ' @ ' + selector)
        const { getDistincts } = usePathApi()
        getDistincts(currentRole, selector, selectorIndex)
          .then((response) => resolve(response), (error) => {
            this.globalStore.handleStoreError(error, 'getDistincts')
          })
      })
    },

    /* -------------------------------------------------------------------------- */

    newPath(currentRole: string) {
      console.log('newPath ' + currentRole)
      this.trail.loaded = false
      this.trail.data = {} as Path // reset before querying
      this.trail.data = {
        'halRole': currentRole,
        'halType': currentRole,
      } as Path
      this.trail.loaded = true
    },

    /* -------------------------------------------------------------------------- */

    createPath(data: Path, currentRole: string) {
      console.log('createPath ' + currentRole)
      this.trail.created = false
      this.trail.data = {} as Path // reset before querying
      const { createPath } = usePathApi()
      createPath(data, currentRole)
        .then((response) => {
          this.trail.data = response
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'createPath')
        })
        .finally(() => {
          this.trail.created = true
        })
    },

    /* -------------------------------------------------------------------------- */

    updatePath(data: Path, currentRole: string) {
      console.log('updatePath ' + currentRole + '#' + data.id)
      this.trail.loaded = false
      this.trail.data = {} as Path // reset before querying
      const { updatePath } = usePathApi()
      updatePath(data, currentRole)
        .then((response) => {
          this.trail.data = response
        }, (error) => {
          // this.globalStore.handleToast('Impossibile aggiornare le informazioni per ' + data.title + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'updatePath')
        })
        .finally(() => {
          this.dirtyAll = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    getContextPaths(currentRole: string, include?: string[], query?: any) {
      console.log('getContextPaths ' + currentRole)
      include = include || []
      const authStore = useAuthStore()
      if (!authStore.authenticated) return
      if (
        authStore.isSuperAdmin ||
        authStore.isAdmin
      ) {
        this.getAllPaths(currentRole, include, query)
      } else {
        this.getPathsOf(authStore.role, authStore.id, currentRole, include, query)
      }
    },

    /* -------------------------------------------------------------------------- */

    getAllPaths(currentRole: string, include?: string[], query?: any) {
      console.log('getAllPaths ' + currentRole)
      include = include || []
      // reset before querying
      this.trails.loaded = false
      this.trails.data = [] as Path[]
      this.trails.pagination = {}
      const { getAllPaths } = usePathApi()
      getAllPaths(currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.trails.data = response.data
          this.trails.pagination = response.pagination
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getAllPaths')
        })
        .finally(() => {
          this.trails.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    getPathsOf(modelRole: string, modelId: any, currentRole: string, include?: string[], query?: any) {
      console.log('getPathsOf ' + currentRole + ' of ' + modelRole + '#' + modelId)
      include = include || []
      // reset before querying
      this.trails.loaded = false
      this.trails.data = [] as Path[]
      this.trails.pagination = {}
      const { getPathsOf } = usePathApi()
      getPathsOf(modelRole, modelId, currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.trails.data = response.data
          this.trails.pagination = response.pagination
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + currentRole + ' di ' + modelRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getPathsOf')
        })
        .finally(() => {
          this.trails.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    deletePathByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('deletePathByID ' + currentRole + '#' + currentId)
      include = include || []
      this.trail.loaded = false
      this.trail.data = {} as Path // reset before querying
      const { deletePathByID } = usePathApi()
      deletePathByID(currentId, currentRole, include)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile cancellare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'deletePathByID')
        })
        .finally(() => {
          this.dirtyAll = true
        })
    },

    /* -------------------------------------------------------------------------- */

    findPathByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('findPathByID ' + currentRole + '#' + currentId)
      include = include || []
      this.trail.loaded = false
      this.trail.data = {} as Path // reset before querying
      const { findPathByID } = usePathApi()
      findPathByID(currentId, currentRole, include)
        .then((response) => {
          this.trail.data = response
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'findPathByID')
        })
        .finally(() => {
          this.trail.loaded = true
          this.dirtyOne = false
        })
    },

    /* -------------------------------------------------------------------------- */

    getRelatedNotices(currentId: any, currentRole: string, noticeRole: string, include?: string[], query?: any) {
      console.log('getRelatedNotices ' + noticeRole + ' of ' + currentRole + '#' + currentId)
      const noticeRoles = noticeRole + 's'
      // reset before querying
      this[noticeRoles].loaded = false
      this[noticeRoles].data = [] as Notice[]
      this[noticeRoles].pagination = {}
      const { getRelatedNotices } = usePathApi()
      getRelatedNotices(currentId, currentRole, noticeRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this[noticeRoles].data = response.data
          this[noticeRoles].pagination = response.pagination
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + noticeRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getRelatedNotices')
        })
        .finally(() => {
          this[noticeRoles].loaded = true
        })
    },


    /* -------------------------------------------------------------------------- */

    addRelatedNotice(currentId: any, currentRole: string, noticeRole: any, noticeData: object) {
      console.log('addRelatedNotice ' + noticeRole + ' of ' + currentRole + '#' + currentId)
      // reset before querying
      this[noticeRole].created = false
      this[noticeRole].data = [] as Notice[]
      const { addRelatedNotice } = usePathApi()
      addRelatedNotice(currentId, currentRole, noticeRole, noticeData)
        .then((response) => {
          this[noticeRole].data = response
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + noticeRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'addRelatedNotice')
        })
        .finally(() => {
          // this[noticeRole].created = true
          this.dirtyOne = true
        })
    },


    /* -------------------------------------------------------------------------- */

    getRelatedMarks(currentId: any, currentRole: string, markRole: string, include?: string[], query?: any) {
      console.log('getRelatedMarks ' + markRole + ' of ' + currentRole + '#' + currentId)
      const markRoles = markRole + 's'
      // reset before querying
      this[markRoles].loaded = false
      this[markRoles].data = [] as Mark[]
      this[markRoles].pagination = {}
      const { getRelatedMarks } = usePathApi()
      getRelatedMarks(currentId, currentRole, markRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this[markRoles].data = response.data
          this[markRoles].pagination = response.pagination
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + markRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getRelatedMarks')
        })
        .finally(() => {
          this[markRoles].loaded = true
        })
    },

    /* -------------------------------------------------------------------------- */

    addRelatedMark(currentId: any, currentRole: string, markRole: any, markData: object) {
      console.log('addRelatedMark ' + markRole + ' of ' + currentRole + '#' + currentId)
      this[markRole].created = false
      this[markRole].data = [] as Mark[] // reset before querying
      const { addRelatedMark } = usePathApi()
      addRelatedMark(currentId, currentRole, markRole, markData)
        .then((response) => {
          this[markRole].data = response
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + markRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'addRelatedMark')
        })
        .finally(() => {
          this[markRole].created = false
          this.dirtyOne = true
        })
    },

    // createRelatedMark(markRole: any, markData: object) {
    //   console.log('createRelatedMark ' + markRole);
    //   const markStore = useMarkStore();
    //   this[markRole].data = markStore.newMark(markRole)
    //   this[markRole].data.id = -1
    //   this[markRole].created = true
    // },

    /* -------------------------------------------------------------------------- */

    unlinkRelatedMark(currentRole: string, currentId: any, markRole: string, markId: any) {
      console.log('unlinkRelatedMark ' + markRole + ' of ' + currentRole + '#' + currentId)
      const { unlinkRelatedMark } = usePathApi()
      unlinkRelatedMark(currentRole, currentId, markRole, markId)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile eliminare le informazioni per ' + markRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'unlinkRelatedMark')
        })
        .finally(() => {
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    linkRelatedMark(currentRole: string, currentId: any, markRole: string, markId: any, include?: string[]) {
      console.log('linkRelatedMark ' + markRole + '#' + markId + ' of ' + currentRole + '#' + currentId)
      include = include || []
      this[markRole].linked = false
      this[markRole].data = [] as Mark[] // reset before querying
      const { linkRelatedMark } = usePathApi()
      linkRelatedMark(currentRole, currentId, markRole, markId, include)
        .then((response) => {
          this[markRole].data = response
        }, (error) => {
          // this.globalStore.handleToast('Impossibile collegare le informazioni per ' + markRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'linkRelatedMark')
        })
        .then(() => {
          //
        })
        .finally(() => {
          this[markRole].linked = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    getRelatedPlayers(currentId: any, currentRole: string, playerRole: string, include?: string[], query?: any) {
      console.log('getRelatedPlayers ' + playerRole + ' of ' + currentRole + '#' + currentId)
      const playerRoles = playerRole + 's'
      // reset before querying
      this[playerRoles].loaded = false
      this[playerRoles].data = [] as Player[]
      this[playerRoles].pagination = {}
      const { getRelatedPlayers } = usePathApi()
      getRelatedPlayers(currentId, currentRole, playerRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this[playerRoles].data = response.data
          this[playerRoles].pagination = response.pagination
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + playerRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getRelatedPlayers')
        })
        .finally(() => {
          this[playerRoles].loaded = true
        })
    },

    /* -------------------------------------------------------------------------- */

    unlinkRelatedPlayer(playerRole: string, playerId: any, currentRole: string, currentId: any) {
      console.log('unlinkRelatedPlayer ' + playerRole + '#' + playerId + ' of ' + currentRole + '#' + currentId)
      const { unlinkRelatedPlayer } = usePathApi()
      unlinkRelatedPlayer(playerRole, playerId, currentRole, currentId)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile eliminare le informazioni per ' + playerRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'unlinkRelatedPlayer')
        })
        .finally(() => {
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    linkRelatedPlayer(playerRole: string, playerId: any, currentRole: string, currentId: any, include?: string[]) {
      console.log('linkRelatedPlayer ' + playerRole + '#' + playerId + ' of ' + currentRole + '#' + currentId)
      include = include || []
      this[playerRole].linked = false
      this[playerRole].data = [] as Player[] // reset before querying
      const { linkRelatedPlayer } = usePathApi()
      linkRelatedPlayer(playerRole, playerId, currentRole, currentId, include)
        .then((response) => {
          this[playerRole].data = response
        }, (error) => {
          // this.globalStore.handleToast('Impossibile collegare le informazioni per ' + playerRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'linkRelatedPlayer')
        })
        .then(() => {
          //
        })
        .finally(() => {
          this[playerRole].linked = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    addCreateRelatedPlayer(playerData: any, playerRole: string, currentRole: string, currentId: any, include?: string[]) {
      console.log('addCreateRelatedPlayer ' + playerRole + ' of ' + currentRole + '#' + currentId)
      include = include || []
      // reset before querying
      this[playerRole].created = false
      this[playerRole].data = [] as Player[]
      const { registerPlayer } = usePlayerApi()
      registerPlayer(playerData, playerRole)
        .then((response) => {
          this.linkRelatedPlayer(playerRole, response.id, currentRole, currentId, include)
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + playerRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'addCreateRelatedPlayer')
        })
        .finally(() => {
          this[playerRole].created = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    // newGpx(currentRole: string) {
    //   console.log('newPath ' + currentRole)
    //   this.gpx.loaded = false
    //   this.gpx.data = {} as Gpx // reset before querying
    //   this.gpx.data = {
    //     name: "(nuovo)",
    //     color: "black"
    //   } as Gpx
    //   this.gpx.loaded = true
    //   return this.gpx
    // },

    /* -------------------------------------------------------------------------- */

  }
})
