import { useGlobalStore } from '@/stores/globalStore'

const globalStore = useGlobalStore()

const appPages = [
  {
    title: "menu.trails",
    url: "/trails",
    iosIcon: "trail",
    isOpen: false,
    key: "trails",
    showIfCan: "readPaths",
    children: [
      {
        title: "menu.trail.add",
        url: "/trails/create",
        key: "trail-create",
        showIfCan: "createPath",
      },
    ],
  },
  {
    title: "menu.pois",
    url: "/pois",
    iosIcon: "poi",
    isOpen: false,
    key: "pois",
    showIfCan: "readMarks",
    children: [
      {
        title: "menu.poi.add",
        url: "/pois/create",
        key: "poi-create",
        showIfCan: "createMark",
      },
      {
        title: "menu.poi.pinpoint",
        url: "/pois?search=hal_type:" + globalStore.poiFilters['pinpoint'].join(','),
        key: "poi-pinpoint",
        showIfCan: "filterMark",
      },
      {
        title: "menu.poi.visit",
        url: "/pois?search=hal_type:" + globalStore.poiFilters['visit'].join(','),
        key: "poi-visit",
        showIfCan: "filterMark",
      },
      {
        title: "menu.poi.rest",
        url: "/pois?search=hal_type:" + globalStore.poiFilters['rest'].join(','),
        key: "poi-rest",
        showIfCan: "filterMark",
      },
      {
        title: "menu.poi.stay",
        url: "/pois?search=hal_type:" + globalStore.poiFilters['stay'].join(','),
        key: "poi-stay",
        showIfCan: "filterMark",
      },
      {
        title: "menu.poi.shop",
        url: "/pois?search=hal_type:" + globalStore.poiFilters['shop'].join(','),
        key: "poi-shop",
        showIfCan: "filterMark",
      },
      {
        title: "menu.poi.service",
        url: "/pois?search=hal_type:" + globalStore.poiFilters['service'].join(','),
        key: "poi-service",
        showIfCan: "filterMark",
      },
    ],
  },
  {
    title: "menu.reports",
    url: "/reports",
    iosIcon: "report",
    isOpen: false,
    key: "reports",
    children: [],
    showIfCan: "readReports",
  },
  {
    title: "menu.advices",
    url: "/advices",
    iosIcon: "advice",
    isOpen: false,
    key: "advices",
    showIfCan: "readAdvices",
    // children: [
    //   {
    //     title: "menu.advice.add",
    //     url: "/advices/create",
    //     key: "advice-create",
    //     showIfCan: "createAdvice",
    //   },
    // ],
  },
  {
    title: "menu.players",
    url: "/players",
    iosIcon: "player",
    isOpen: false,
    key: "players",
    showIfCan: "readPlayers",
    children: [{
      title: "menu.player.admins",
      url: "/admins",
      key: "player-admin",
      showIfCan: "readAdmins",
    }, {
      title: "menu.player.managers",
      url: "/managers",
      key: "player-manager",
      showIfCan: "readManagers",
    }, {
      title: "menu.player.owners",
      url: "/owners",
      key: "player-owner",
      showIfCan: "readOwners",
    }, {
      title: "menu.player.walkers",
      url: "/walkers",
      key: "player-walker",
      showIfCan: "readWalkers",
    }],
  },
  // {
  //   title: "menu.settings",
  //   url: "/settings",
  //   iosIcon: "cog",
  //   isOpen: false,
  //   key: "settings",
  //   children: [],
  //   role: 'admin,manager,owner'
  // },
]


export default appPages
