export default {
  "validator": {
    "coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["‘lat,lon‘ è un valore richiesto (formato DD con 5 decimali ex. 41.40338, 2.17403)"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "i18n": {
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "menu": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cammini"])},
    "trail": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Cammino"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo Cammino"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Cammino"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Cammino"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna Cammino"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna Cammino"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "pois": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti di Interesse"])},
    "poi": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Pdi"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo Pdi"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Pdi"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Pdi"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna Pdi"])},
      "pinpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto GPS"])},
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitare"])},
      "rest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangiare"])},
      "stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dormire"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquistare"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi Utili"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "advices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisi"])},
    "advice": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo Avviso"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Avviso"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Avviso"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna Avviso"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnalazioni"])},
    "report": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Segnalazione"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna Segnalazione"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
    "player": {
      "admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratori"])},
      "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestori dei Cammini"])},
      "owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestori delle Attività"])},
      "walkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti della app"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo utente"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Utente"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Utente"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna Utente"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
    "user": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "action": {
    "add_gpx_variante_2_trail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi variante al percorso"])},
    "notify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia notifica"])},
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
    "passwordupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "resetpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata?"])},
    "insertusername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inserisci la tua email"])},
    "insertpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inserisci la tua password"])},
    "insertsamepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inserisci la stessa password"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "detach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scollega"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registra"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva"])},
    "deactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattiva"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associa"])},
    "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distacca"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "transform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasforma"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "particle": {
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubblico"])},
    "publics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubblici"])},
    "public-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubblica"])},
    "publics-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubbliche"])},
    "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubblicato"])},
    "publisheds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubblicati"])},
    "published-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubblicata"])},
    "publisheds-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pubblicate"])},
    "visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" visibile"])},
    "visibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" visibili"])},
    "visible-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" visibile"])},
    "visibles-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" visibili"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attivo"])},
    "actives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attivi"])},
    "active-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attiva"])},
    "actives-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" attive"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" totale"])},
    "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" totali"])},
    "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" condiviso"])},
    "shareds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" condivisi"])},
    "shared-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" condivisa"])},
    "shareds-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" condivise"])},
    "thismonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mese corrente"])},
    "lastmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mese precedente"])},
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" un "])},
    "one-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" una "])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" per il "])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" di "])},
    "the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" il "])},
    "the-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" la "])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" dal "])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" al "])},
    "toone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a un "])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" o "])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" in "])},
    "gpx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" traccia GPX"])},
    "gpxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" varianti al percorso"])},
    "thetrail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" il Cammino"])},
    "ontrail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sul Cammino"])},
    "theportal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" il Portale"])},
    "onportal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sul Portale"])},
    "ofportal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" del Portale"])},
    "ofportals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" dei Portali"])},
    "withactivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" con le Attività"])},
    "withpoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" con il Pdi"])},
    "ofpoi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" del Pdi"])},
    "oftrail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" di percorrenza"])},
    "onmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sulla Mappa"])},
    "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["principale"])},
    "primary-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["principale"])},
    "primarys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["principali"])},
    "primarys-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["principali"])},
    "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secondario"])},
    "secondary-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secondaria"])},
    "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aggiuntivo"])},
    "additional-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aggiuntiva"])},
    "additionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aggiuntivi"])},
    "additionals-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aggiuntive"])},
    "visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visibilità "])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" elemento"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" elementi"])},
    "thestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa attività è abilitata alla timbratura delle credenziali per i seguenti Cammini"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "label": {
    "graph": {
      "walkers": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento mensile camminatori"])}
      },
      "readings": {
        "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andamento mensile letture"])}
      }
    },
    "colors": {
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nero"])},
      "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu"])},
      "cyan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciano"])},
      "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giallo"])},
      "fuchsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuchsia"])},
      "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arancione"])},
      "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verde"])},
      "bordeaux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bordeaux"])}
    },
    "gpxs_name_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della variante (IT)"])},
    "gpxs_name_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della variante (EN)"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["num"])},
    "choosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["che hai scelto"])},
    "langchooser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la lingua per il data entry"])},
    "langchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua dei contenuti cambiata in"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto"])},
    "dologin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, fai il login."])},
    "doaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, scegli una azione dal menu laterale..."])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
    "image": {
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formato consigliato orizzontale, jpg o png (max. 1mb per immagine)"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "filldata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completa tutti i campi richiesti"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni generali"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia"])},
    "coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate"])},
    "downloadwkp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica WalkPlus"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termini e condizioni del servizio"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy & cookie policy"])},
    "drag": {
      "markers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascina le icone di posizionamento per modificare le coordinate di inizio e fine"])},
      "marker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascina l’icona di posizionamento per modificare le coordinate"])},
      "coords": {
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate aggiornate"])}
      }
    },
    "gpxchoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica traccia GPX"])},
    "gpxcolor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore della traccia nella mappa"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "model": {
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "gpx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPX"])},
    "gpxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPX"])},
    "gpx_variante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["variante"])},
    "gpx_varianti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varianti"])},
    "trail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cammino"])},
    "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cammini"])},
    "trails-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cammini"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luoghi"])},
    "pois": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti di Int."])},
    "towns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "pois-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdi"])},
    "poi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdi"])},
    "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
    "players-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
    "advices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisi"])},
    "advices-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisi"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnalazioni"])},
    "reports-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnalaz."])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnalazione"])},
    "maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappe"])},
    "maps-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappe"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mappa"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni in-app"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestore"])},
    "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestori"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestore"])},
    "owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestori"])},
    "directions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicazioni"])},
    "utils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeri utili"])},
    "sorter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina Comuni"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "walkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camminatori"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche globali"])},
    "nfcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portali"])},
    "nfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portale"])},
    "portals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portali"])},
    "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portale"])},
    "stamps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timbrature"])},
    "stamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timbratura"])},
    "readings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letture"])},
    "readings-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letture"])},
    "reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettura"])},
    "interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interazioni"])},
    "interaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interazione"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schede"])},
    "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheda"])},
    "timemedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo Medio"])},
    "speedmedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità Media"])},
    "coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tappa"])},
    "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tappe"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero utile"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeri utili"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazioni"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condivisione"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condivisioni"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "tipologia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "pinpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "pharmacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "staying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "player": {
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Completo"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito web"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "admin": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "manager": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "owner": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "walker": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "trail": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "startcoords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
    "finishcoords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate"])},
    "primaryimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine in evidenza"])},
    "primaryimagedesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l’immagine in evidenza viene mostrata nell’elenco dei Cammini e nella schermata del Cammino, sopra al titolo."])},
    "uplevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dislivello in salita"])},
    "downlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dislivello in discesa"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza complessiva"])},
    "km-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Km"])},
    "stepsfootcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di tappe consigliate a piedi"])},
    "stepsmtbcount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di tappe consigliate in mtb"])},
    "moreimages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi altre immagini"])},
    "moreimagesmax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(max 10)"])},
    "moreimagesdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["queste immagini creano una gallery nella schermata del Cammino."])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia del Cammino"])},
    "inapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni in-app"])},
    "managername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizzazione che gestisce il Cammino"])},
    "managerphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "manageraddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "manageremail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "managerzip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
    "managercity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "managerprovince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
    "managerwebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito web"])},
    "managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti che gestiscono questo Cammino"])},
    "moregpxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi altre tracce GPX"])},
    "moregpxsmax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(max 5)"])},
    "moregpxsdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["queste sono tracce alternative per il Cammino."])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "place": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "step": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "link": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "poi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sottotitolo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "trail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cammino"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "readings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letture"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
    "coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate"])},
    "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portale"])},
    "portalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Portale"])},
    "portallocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove si trova il portale"])},
    "stamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timbratura"])},
    "stampdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicazioni per la timbratura"])},
    "moreimages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi immagini"])},
    "moreimagesmax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(max 10)"])},
    "moreimagesdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["queste immagini creano una gallery nella schermata del Pdi."])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sito web"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "tripadvisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TripAdvisor"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link prenotazione Booking"])},
    "airbnb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link prenotazione AirBnb"])},
    "interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interazioni"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
    "categories": {
      "pinpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto GPS"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero utile"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di interesse storico-culturale"])},
      "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio"])},
      "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontana / Acqua potabile"])},
      "pharmacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farmacia"])},
      "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ospedale"])},
      "staying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albergo / B&B / Affittacamere / Ostello"])},
      "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campeggio"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristorante"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio / Tabaccheria"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi Utili"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["???"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di stelle"])},
    "servicesadd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi servizi"])},
    "services": {
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristorante"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
      "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camere"])},
      "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campeggio"])},
      "parking-car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio auto"])},
      "parking-van": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio camper"])},
      "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WiFi"])},
      "pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscina"])},
      "area-picnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area picnic"])},
      "area-bivac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bivacco"])},
      "area-playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parco giochi"])},
      "care-handicaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi per disabili"])},
      "care-infants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi per neonati"])},
      "allowed-pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animali ammessi"])},
      "showers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docce"])},
      "toilets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi igienici"])},
      "shuttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio navetta"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "tab": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
      "pinpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
      "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
      "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
      "pharmacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "hospital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "staying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "camping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portale"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti che gestiscono questa Attività"])},
    "trails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associa uno o più Cammini"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "notice": {
    "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gravità"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titolo"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avviso"])},
    "trail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cammino"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problematica"])},
    "coords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate"])},
    "accuracy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuratezza"])},
    "reasons": {
      "treedown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albero caduto"])},
      "smallslip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piccola frana"])},
      "poorsign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnaletica insufficiente"])},
      "tallgrass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erba alta"])},
      "tooveg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiero molto infrascato"])},
      "noaccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentiero non accessibile"])},
      "badfork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bivio mal segnato"])},
      "nocrossing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guado impraticabile"])},
      "verymuddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molto fango"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "tab": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
      "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "user": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "passwordupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna la password"])},
    "passwordconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  }
}