

const version = process.env.VUE_APP_VERSION || '0.56.0'

import { useI18n } from 'vue-i18n'

// import axios from 'axios';

import {
  IonApp,
  IonContent,
  IonHeader,
  IonFooter,
  IonSplitPane,
  IonMenu,
  IonIcon,
  IonMenuToggle,
  IonButtons,
  IonButton,
  IonLabel,
  IonList,
  IonListHeader,
  IonRouterOutlet,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  // IonBackButton,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/vue'

// import SwitchLanguage from '@/components/SwitchLanguage.vue';

import NavigationMenu from '@/components/shared/NavigationMenu.vue'

import { useRouter } from 'vue-router'

import {
  menuOutline,
  logOutOutline,
  personCircleOutline,
  languageOutline,
} from 'ionicons/icons'

import { defineComponent, ref, watch } from 'vue'

import { useAuthStore, authStorage } from '@/stores/authStore'

import { usePathStore } from '@/stores/pathStore'
import { useMarkStore } from '@/stores/markStore'
import { useNoticeStore } from '@/stores/noticeStore'
import { usePlayerStore } from '@/stores/playerStore'

import { useGlobalStore } from '@/stores/globalStore'

import axios from 'axios'

import i18n from '@/i18n/i18n'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonHeader,
    IonFooter,
    IonSplitPane,
    IonMenu,
    IonIcon,
    IonMenuToggle,
    IonButtons,
    IonButton,
    IonLabel,
    IonList,
    IonListHeader,
    IonRouterOutlet,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    // IonBackButton,
    // SwitchLanguage,
    NavigationMenu,
    IonSelect,
    IonSelectOption,
    IonText,
  },
  setup() {

    const { t, locale, availableLocales } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    })

    const router = useRouter()

    const globalStore = useGlobalStore()
    const authStore = useAuthStore()
    const pathStore = usePathStore()
    const markStore = useMarkStore()
    const noticeStore = useNoticeStore()
    const playerStore = usePlayerStore()

    const signOut = async () => {
      await authStore.signOut()
      router.replace({ name: 'login' })
      router.go(0)
    }

    const showStatus = ref(false)

    function toggleStatus() {
      if (authStore.isSuperAdmin) {
        showStatus.value = !showStatus.value
      } else console.log(authStore.can)
      return true
    }

    const languagePopoverOptions: any = {
      cssClass: 'language-selector',
    }

    const profilePopoverOptions: any = {
      cssClass: 'profile-selector',
    }

    const editProfile = (event: any) => {
      switch (event.detail.value) {
        case 'profile':
          router.push({ name: 'profile' })
          break
        case 'logout':
          signOut()
          break
      }
    }

    const setApiHeader = () => {
      i18n.global.locale.value = locale.value
      axios.defaults.headers.common['X-SET-LANGUAGE'] = locale.value
      authStore.lang4contents = locale.value
      authStorage.value.lang4contents = locale.value
      console.log('setApiHeader ' + locale.value.toUpperCase())

      globalStore.handleToast(
        t('label.langchanged') + ' ' + locale.value.toUpperCase()
      )

      pathStore.dirtyOne = true
      pathStore.dirtyAll = true
      markStore.dirtyOne = true
      markStore.dirtyAll = true
      noticeStore.dirtyOne = true
      noticeStore.dirtyAll = true
      playerStore.dirtyOne = true
      playerStore.dirtyAll = true

      // router.go(0);

    }

    const loadTrailsForFilters = () => {
      pathStore.getContextDistincts('trail', 'title', 'id').then((response: any) => {
        globalStore.trailFilters.value = response
      })
    }

    if (authStore.authenticated) {
      loadTrailsForFilters()
    }

    watch(
      () => authStore.authenticated,
      () => {
        if (authStore.authenticated) {
          loadTrailsForFilters()
        }
      }
    )

    return {
      i18n,
      t,
      locale,
      availableLocales,
      menuOutline,
      logOutOutline,
      personCircleOutline,
      languageOutline,
      router,
      signOut,
      authStore,
      authStorage,
      showStatus,
      toggleStatus,
      globalStore,
      setApiHeader,
      languagePopoverOptions,
      profilePopoverOptions,
      editProfile,
      version,
    }
  },
})
