import { defineStore } from 'pinia'

import axios from 'axios'
import router from '@/router';

import { useGlobalStore } from '@/stores/globalStore'

import usePlayerApi from '@/stores/api/playerApi';
import { User, Player } from '@/stores/interfaces';

import { useLocalStorage } from '@vueuse/core'
import i18n from '@/i18n/i18n';
import moment from 'moment';

const globalStore = useGlobalStore();

const playerApi = usePlayerApi()

export const authStorage = useLocalStorage('authStorage', {
  lang4contents: process.env.VUE_APP_I18N_LOCALE,
  id: 0,
  authenticated: false,
  user: {} as User,
  player: {} as Player,
  isSuperAdmin: false,
  isAdmin: false,
  isManager: false,
  isOwner: false,
  role: '',
  can: {
    profile: 0,
    readPaths: false,
    editPaths: false,
    createPath: false,
    filterPath: false,
    editCurrentPath: false,
    deleteCurrentPath: false,
    handleCurrentPathManagers: false,
    createCurrentPathManagers: false,
    handleCurrentPathMarks: false,
    readMarks: false,
    editMarks: false,
    createMark: false,
    filterMark: false,
    editCurrentMark: false,
    deleteCurrentMark: false,
    handleCurrentMarkOwners: false,
    handleCurrentMarkPaths: false,
    createCurrentMarkOwners: false,
    readAdvices: false,
    editAdvices: false,
    createAdvice: false,
    filterAdvices: false,
    editCurrentAdvice: false,
    deleteCurrentAdvice: false,
    readReports: false,
    editReports: false,
    createReport: false,
    filterReports: false,
    editCurrentReport: false,
    deleteCurrentReport: false,
    editCurrentPlayer: false,
    readAdmins: false,
    filterAdmins: false,
    editAdmins: false,
    editCurrentAdmin: false,
    deleteCurrentAdmin: false,
    readManagers: false,
    filterManagers: false,
    editManagers: false,
    profileManager: false,
    editCurrentManager: false,
    deleteCurrentManager: false,
    readOwners: false,
    filterOwners: false,
    editOwners: false,
    profileOwner: false,
    editCurrentOwner: false,
    deleteCurrentOwner: false,
    readWalkers: false,
    filterWalkers: false,
    editWalkers: false,
    profileWalker: false,
    editCurrentWalker: false,
    deleteCurrentWalker: false,
    readPlayers: false
  },
  currentPathManagers: [],
  currentMarkOwners: [],
})

export const useAuthStore = defineStore({

  id: 'authStore',

  /* --------------------------------- STATE -------------------------------- */

  state: () => (authStorage.value),

  /* --------------------------------- GETTERS -------------------------------- */

  getters: {
  },

  /* --------------------------------- ACTIONS -------------------------------- */

  actions: {

    /* -------------------------------------------------------------------------- */

    setPermissions() {

      this.lang4contents = authStorage.value.lang4contents || process.env.VUE_APP_I18N_LOCALE

      console.log('setPermissions ' + this.lang4contents.toUpperCase());

      i18n.global.locale.value = this.lang4contents

      moment.locale(this.lang4contents)

      axios.defaults.headers.common['X-SET-LANGUAGE'] = i18n.global.locale.value;

      this.id = this.player?.id
      this.role = this.player?.halRole
      this.isSuperAdmin = this.player?.halRole == 'superadmin'
      this.isAdmin = this.isSuperAdmin || this.player?.halRole == 'admin'
      this.isManager = this.isSuperAdmin || this.player?.halRole == 'manager'
      this.isOwner = this.isSuperAdmin || this.player?.halRole == 'owner'
      const hasPathsToManage = this.isManager && (Object.keys(this.player?.trails as Record<string, any>).length > 0)
      const hasMarksToManage = this.isOwner && (Object.keys(this.player?.pois as Record<string, any>).length > 0)
      this.can = {
        profile: !!this.id,
        /* ---------------------------------- PATH ---------------------------------- */
        readPaths: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        editPaths: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        createPath: this.isSuperAdmin || this.isAdmin,
        filterPath: this.isSuperAdmin || this.isAdmin,
        editCurrentPath: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        deleteCurrentPath: this.isSuperAdmin || this.isAdmin,
        handleCurrentPathManagers: this.isSuperAdmin || this.isAdmin,
        createCurrentPathManagers: this.isSuperAdmin || this.isAdmin,
        handleCurrentPathMarks: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        /* ---------------------------------- MARKS ---------------------------------- */
        readMarks: this.isSuperAdmin || this.isAdmin || hasPathsToManage || hasMarksToManage,
        editMarks: this.isSuperAdmin || this.isAdmin || hasPathsToManage || hasMarksToManage,
        createMark: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        filterMark: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        editCurrentMark: this.isSuperAdmin || this.isAdmin || hasPathsToManage || hasMarksToManage,
        deleteCurrentMark: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        handleCurrentMarkOwners: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        handleCurrentMarkPaths: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        createCurrentMarkOwners: this.isSuperAdmin || this.isAdmin || hasPathsToManage,
        /* ---------------------------------- ADVICES ---------------------------------- */
        readAdvices: this.isSuperAdmin || this.isAdmin || this.isManager,
        editAdvices: this.isSuperAdmin || this.isAdmin || this.isManager,
        createAdvice: this.isSuperAdmin || this.isAdmin || this.isManager,
        filterAdvices: this.isSuperAdmin || this.isAdmin || this.isManager,
        editCurrentAdvice: this.isSuperAdmin || this.isAdmin || this.isManager,
        deleteCurrentAdvice: this.isSuperAdmin || this.isAdmin || this.isManager,
        /* ---------------------------------- REPORTS ---------------------------------- */
        readReports: this.isSuperAdmin || this.isAdmin || this.isManager,
        editReports: this.isSuperAdmin || this.isAdmin || this.isManager,
        createReport: this.isSuperAdmin || this.isAdmin || this.isManager,
        filterReports: this.isSuperAdmin || this.isAdmin || this.isManager,
        editCurrentReport: this.isSuperAdmin || this.isAdmin || this.isManager,
        deleteCurrentReport: this.isSuperAdmin || this.isAdmin || this.isManager,
        /* ---------------------------------- PLAYERS ---------------------------------- */
        editCurrentPlayer: this.isSuperAdmin || this.isAdmin || true,
        /* ---------------------------------- ADMINS ---------------------------------- */
        readAdmins: this.isSuperAdmin,
        filterAdmins: this.isSuperAdmin,
        editAdmins: this.isSuperAdmin,
        editCurrentAdmin: this.isSuperAdmin,
        deleteCurrentAdmin: this.isSuperAdmin,
        /* ---------------------------------- MANAGERS ---------------------------------- */
        readManagers: this.isSuperAdmin || this.isAdmin,
        filterManagers: this.isSuperAdmin || this.isAdmin || this.isManager,
        editManagers: this.isSuperAdmin || this.isAdmin || this.isManager,
        profileManager: this.isSuperAdmin || this.isAdmin,
        editCurrentManager: this.isSuperAdmin || this.isAdmin || this.isManager,
        deleteCurrentManager: this.isSuperAdmin,
        /* ---------------------------------- OWNERS ---------------------------------- */
        readOwners: this.isSuperAdmin || this.isAdmin || this.isManager,
        filterOwners: this.isSuperAdmin || this.isAdmin || this.isManager,
        editOwners: this.isSuperAdmin || this.isAdmin || this.isManager,
        profileOwner: this.isSuperAdmin || this.isAdmin || this.isManager,
        editCurrentOwner: this.isSuperAdmin || this.isAdmin || this.isManager,
        deleteCurrentOwner: this.isSuperAdmin,
        /* ---------------------------------- WALKERS ---------------------------------- */
        readWalkers: this.isSuperAdmin || this.isAdmin,
        filterWalkers: this.isSuperAdmin || this.isAdmin,
        editWalkers: this.isSuperAdmin || this.isAdmin,
        profileWalker: this.isSuperAdmin,
        editCurrentWalker: this.isSuperAdmin || this.isAdmin,
        deleteCurrentWalker: this.isSuperAdmin,
        /* ---------------------------------- PLAYERS ---------------------------------- */
        readPlayers: this.isSuperAdmin || this.isAdmin || this.isManager,
      }

    },

    /* -------------------------------------------------------------------------- */

    isPlayerAmong(players?: any): boolean {

      // console.log('isPlayerAmong');
      let isPlayerAmong = false
      isPlayerAmong = isPlayerAmong || this.isSuperAdmin
      isPlayerAmong = isPlayerAmong || this.isAdmin
      isPlayerAmong = isPlayerAmong || this.isManager

      isPlayerAmong = isPlayerAmong || players.id && (players.id == this.player.id)

      isPlayerAmong = isPlayerAmong || players.value && (players.value.id == this.player.id)

      isPlayerAmong = isPlayerAmong ||
        players instanceof Array &&
        (players.find((item: any) => item.id == this.player.id) != null)

      isPlayerAmong = isPlayerAmong || players instanceof Object &&
        (players[this.player.id] != null)

      // console.error(isPlayerAmong)

      return isPlayerAmong;
    },

    /* -------------------------------------------------------------------------- */

    async setPassword(player: string, id: any, otp: string, checktotp: string, password: string) {
      console.log('setPassword');
      await axios.get(globalStore.apiBaseUrl + '/sanctum/csrf-cookie')
      await axios.post(globalStore.apiBaseUrl + '/' + player + '/' + id + '/confirm', { otp: otp, checktotp: checktotp, password: password, init: true })
        .then((response) => {
          if (response.data?.id) {
            globalStore.handleToast("Password settata correttamente. Verrai reindirizzato per fare il login...")
            router.replace({ name: 'login' })
          } else {
            throw new Error("Utente non disponibile")
          }
        })
        .catch((error: any) => {
          console.log(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)');
          return false;
        })
    },

    /* -------------------------------------------------------------------------- */

    async signIn(username: string, password: string) {
      console.log('signIn');
      await axios.get(globalStore.apiBaseUrl + '/sanctum/csrf-cookie')
      await axios.post(globalStore.apiBaseUrl + '/login', { email: username, password: password })
        .then((response) => {
          const user = response.data //.data
          this.user = playerApi.remapUserFromApi([user])[0]
          return this.getMe()
        })
      // .catch(() => {
      //   this.signOut()
      // })
    },

    /* -------------------------------------------------------------------------- */

    async signOut() {
      console.log('signOut');
      return await axios.post(globalStore.apiBaseUrl + '/logout')
        .then(() => {
          return this.getMe()
        })
    },

    /* -------------------------------------------------------------------------- */

    async getMe() {
      console.log('getMe');
      return await axios.get(globalStore.apiBaseUrl + '/me')
        .then((response) => {
          this.authenticated = true
          this.player = playerApi.remapPlayerFromApi([response.data])[0]
          if ('admin,manager,owner'.indexOf(this.player.halRole) == -1) {
            this.signOut()
          }
          this.setPermissions()
        }).catch((err) => {
          this.authenticated = false
          this.user = {} as User
          this.player = {} as Player
          this.id = 0
          this.role = ''
          this.can = {}
          if (router.currentRoute.value.name !== 'login' && router.currentRoute.value.name !== 'setpassword' && err.message.indexOf('401') !== false) {
            router.replace({ name: 'login' })
          }
        })
    }
  },

  /* -------------------------------------------------------------------------- */

})
