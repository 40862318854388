import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: { name: 'home-page' }
  },
  {
    path: '/home',
    name: 'home-page',
    component: () => import('@/components/global/pages/Home.vue')
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/global/pages/Login.vue"),
  },
  {
    path: "/setpassword",
    name: "setpassword",
    alias: "/confirm",
    component: () => import("@/components/global/pages/SetPassword.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/components/players/pages/Profile.vue"),
  },


  /* ---------------------------------- TRAIL --------------------------------- */

  {
    path: '/:trail(trails?)',
    name: 'trail-collection',
    component: () => import('@/components/trails/pages/TrailCollection.vue'),
  }, {
    path: '/:trail(trails?)/create',
    name: 'trail-create',
    component: () => import('@/components/trails/pages/TrailResourceCreate.vue'),
    children: [
      {
        path: '',
        name: 'trail-resource-create',
        component: () => import('@/components/trails/tabs/CreateTab.vue')
      },
    ],
  }, {
    path: '/:trail(trails?)/:id(\\d+)/',
    name: 'trail-resource-overview',
    component: () => import('@/components/trails/pages/TrailResourceOverview.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'trail-resource-dashboard',
        component: () => import('@/components/trails/tabs/DashboardTab.vue')
      }, {
        path: 'about',
        name: 'trail-resource-about',
        component: () => import('@/components/trails/tabs/AboutTab.vue'),
      }, {
        path: ':advice(advices?)',
        name: 'trail-resource-advice',
        component: () => import('@/components/trails/tabs/AdviceTab.vue')
      }, {
        path: ':report(reports?)',
        name: 'trail-resource-report',
        component: () => import('@/components/trails/tabs/ReportTab.vue')
      }, {
        path: ':poi(pois?)',
        name: 'trail-resource-poi',
        component: () => import('@/components/trails/tabs/PoiTab.vue')
      }, {
        path: ':map(maps?)',
        name: 'trail-resource-map',
        component: () => import('@/components/trails/tabs/MapTab.vue')
      }, {
        path: '',
        redirect: { name: 'trail-resource-dashboard' },
      }
    ]
  }, {
    path: '/:trail(trails?)/:id(\\d+)/edit/',
    name: 'trail-resource-edit',
    component: () => import('@/components/trails/pages/TrailResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'trail-resource-edit-about' }
      }, {
        path: 'about',
        name: 'trail-resource-edit-about',
        component: () => import('@/components/trails/tabs/AboutTab.vue')
      }, {
        path: ':history(history?i?e?s?)',
        name: 'trail-resource-edit-history',
        component: () => import('@/components/trails/tabs/HistoryTab.vue')
      }, {
        path: ':place(places?)',
        name: 'trail-resource-edit-place',
        component: () => import('@/components/trails/tabs/PlaceTab.vue')
      }, {
        path: ':manager(managers?)',
        name: 'trail-resource-edit-manager',
        component: () => import('@/components/trails/tabs/ManagerTab.vue')
      }, {
        path: ':step(steps?)',
        name: 'trail-resource-edit-step',
        component: () => import('@/components/trails/tabs/StepTab.vue')
      }, {
        path: ':sorter(sorters?)',
        name: 'trail-resource-edit-sorter',
        component: () => import('@/components/trails/tabs/SorterTab.vue')
      }, {
        path: ':util(utils?)',
        name: 'trail-resource-edit-util',
        component: () => import('@/components/trails/tabs/LinkTab.vue')
      }, {
        path: ':setting(settings?)',
        name: 'trail-resource-edit-setting',
        component: () => import('@/components/trails/tabs/SettingTab.vue')
      }, {
        path: ':app(apps?)',
        name: 'trail-resource-edit-app',
        component: () => import('@/components/trails/tabs/AppTab.vue')
      },
    ]
  },

  /* ---------------------------------- POI --------------------------------- */

  {
    path: '/:poi(pois?)',
    name: 'poi-collection',
    component: () => import('@/components/pois/pages/PoiCollection.vue'),
  }, {
    path: '/:poi(pois?)/create',
    name: 'poi-create',
    component: () => import('@/components/pois/pages/PoiResourceCreate.vue'),
    children: [
      {
        path: '',
        name: 'poi-resource-create',
        component: () => import('@/components/pois/tabs/CreateTab.vue')
      },
    ],
  }, {
    path: '/:poi(pois?)/:id(\\d+)/',
    name: 'poi-resource-overview',
    component: () => import('@/components/pois/pages/PoiResourceOverview.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'poi-resource-dashboard' },
      }, {
        path: 'dashboard',
        name: 'poi-resource-dashboard',
        component: () => import('@/components/pois/tabs/DashboardTab.vue')
      }, {
        path: 'about',
        name: 'poi-resource-about',
        component: () => import('@/components/pois/tabs/AboutTab.vue')
      }, {
        path: ':map(maps?)',
        name: 'poi-resource-map',
        component: () => import('@/components/pois/tabs/MapTab.vue')
      },
    ]
  }, {
    path: '/:poi(pois?)/:id(\\d+)/edit/',
    name: 'poi-resource-edit',
    component: () => import('@/components/pois/pages/PoiResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'poi-resource-edit-about' }
      }, {
        path: 'about',
        name: 'poi-resource-edit-about',
        component: () => import('@/components/pois/tabs/AboutTab.vue')
      },
      {
        path: 'pinpoint',
        name: 'poi-resource-edit-pinpoint',
        component: () => import('@/components/pois/tabs/PinpointTab.vue')
      },
      {
        path: 'location',
        name: 'poi-resource-edit-location',
        component: () => import('@/components/pois/tabs/LocationTab.vue')
      },
      {
        path: 'water',
        name: 'poi-resource-edit-water',
        component: () => import('@/components/pois/tabs/WaterTab.vue')
      },
      {
        path: 'parking',
        name: 'poi-resource-edit-parking',
        component: () => import('@/components/pois/tabs/ParkingTab.vue')
      },
      {
        path: 'pharmacy',
        name: 'poi-resource-edit-pharmacy',
        component: () => import('@/components/pois/tabs/PharmacyTab.vue')
      },
      {
        path: 'hospital',
        name: 'poi-resource-edit-hospital',
        component: () => import('@/components/pois/tabs/HospitalTab.vue')
      },
      {
        path: 'staying',
        name: 'poi-resource-edit-staying',
        component: () => import('@/components/pois/tabs/StayingTab.vue')
      },
      {
        path: 'camping',
        name: 'poi-resource-edit-camping',
        component: () => import('@/components/pois/tabs/CampingTab.vue')
      },
      {
        path: 'restaurant',
        name: 'poi-resource-edit-restaurant',
        component: () => import('@/components/pois/tabs/RestaurantTab.vue')
      },
      {
        path: 'bar',
        name: 'poi-resource-edit-bar',
        component: () => import('@/components/pois/tabs/BarTab.vue')
      },
      {
        path: 'shop',
        name: 'poi-resource-edit-shop',
        component: () => import('@/components/pois/tabs/ShopTab.vue')
      },
      {
        path: 'service',
        name: 'poi-resource-edit-service',
        component: () => import('@/components/pois/tabs/ServiceTab.vue')
      },
      {
        path: ':portal(portal?)',
        name: 'poi-resource-edit-portal',
        component: () => import('@/components/pois/tabs/PortalTab.vue')
      },
      {
        path: ':stamp(stamp?)',
        name: 'poi-resource-edit-stamp',
        component: () => import('@/components/pois/tabs/StampTab.vue')
      },
      {
        path: ':setting(settings?)',
        name: 'poi-resource-edit-setting',
        component: () => import('@/components/pois/tabs/SettingTab.vue')
      },
    ]
  },

  /* ---------------------------------- ADVICES --------------------------------- */

  {
    path: '/:advice(advices?)',
    name: 'advice-collection',
    component: () => import('@/components/advices/pages/AdviceCollection.vue'),
  }, {
    path: '/:advice(advices?)/:id(\\d+)/',
    name: 'advice-resource-overview',
    component: () => import('@/components/advices/pages/AdviceResourceOverview.vue'),
    children: [
      {
        path: '',
        // redirect: { name: 'advice-resource-dashboard' },
        redirect: { name: 'advice-resource-edit-about' },
      }, {
        path: 'dashboard',
        name: 'advice-resource-dashboard',
        component: () => import('@/components/advices/tabs/DashboardTab.vue')
      },
    ]
  }, {
    path: '/:advice(advices?)/:id(\\d+)/edit/',
    name: 'advice-resource-edit',
    component: () => import('@/components/advices/pages/AdviceResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'advice-resource-edit-about' }
      }, {
        path: 'about',
        name: 'advice-resource-edit-about',
        component: () => import('@/components/advices/tabs/AboutTab.vue')
      },
    ]
  },

  /* ---------------------------------- REPORTS --------------------------------- */

  {
    path: '/:report(reports?)',
    name: 'report-collection',
    component: () => import('@/components/reports/pages/ReportCollection.vue'),
  }, {
    path: '/:report(reports?)/:id(\\d+)/',
    name: 'report-resource-overview',
    component: () => import('@/components/reports/pages/ReportResourceOverview.vue'),
    children: [
      {
        path: '',
        // redirect: { name: 'report-resource-dashboard' },
        redirect: { name: 'report-resource-edit-about' },
      }, {
        path: 'dashboard',
        name: 'report-resource-dashboard',
        component: () => import('@/components/reports/tabs/DashboardTab.vue')
      },
    ]
  }, {
    path: '/:report(reports?)/:id(\\d+)/edit/',
    name: 'report-resource-edit',
    component: () => import('@/components/reports/pages/ReportResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'report-resource-edit-about' }
      }, {
        path: 'about',
        name: 'report-resource-edit-about',
        component: () => import('@/components/reports/tabs/AboutTab.vue')
      },

    ]
  },

  /* ---------------------------------- PLAYERS --------------------------------- */
  {
    path: '/:player(players?)',
    name: 'player-collection',
    component: () => import('@/components/players/pages/PlayerCollection.vue'),
  },

  /* ---------------------------------- ADMINS --------------------------------- */
  {
    path: '/:admin(admins?)/',
    name: 'admin-collection',
    component: () => import('@/components/admins/pages/AdminCollection.vue'),
  }, {
    path: '/:admin(admins?)/:id(\\d+)/',
    redirect: { name: 'admin-resource-edit' }
  },
  {
    path: '/:admin(admins?)/:id(\\d+)/edit/',
    name: 'admin-resource-edit',
    component: () => import('@/components/admins/pages/AdminResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'admin-resource-edit-about' }
      }, {
        path: 'about',
        name: 'admin-resource-edit-about',
        component: () => import('@/components/admins/tabs/AboutTab.vue')
      }, {
        path: 'profile',
        name: 'admin-resource-edit-profile',
        component: () => import('@/components/players/tabs/ProfileTab.vue')
      },
    ],
  },

  /* ---------------------------------- MANAGERS --------------------------------- */
  {
    path: '/:manager(managers?)/',
    name: 'manager-collection',
    component: () => import('@/components/managers/pages/ManagerCollection.vue'),
  }, {
    path: '/:manager(managers?)/:id(\\d+)/',
    redirect: { name: 'manager-resource-edit' }
  },
  {
    path: '/:manager(managers?)/:id(\\d+)/edit/',
    name: 'manager-resource-edit',
    component: () => import('@/components/managers/pages/ManagerResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'manager-resource-edit-about' }
      }, {
        path: 'about',
        name: 'manager-resource-edit-about',
        component: () => import('@/components/managers/tabs/AboutTab.vue')
      }, {
        path: 'profile',
        name: 'manager-resource-edit-profile',
        component: () => import('@/components/players/tabs/ProfileTab.vue')
      },
    ],
  },

  /* ---------------------------------- OWNERS --------------------------------- */
  {
    path: '/:owner(owners?)/',
    name: 'owner-collection',
    component: () => import('@/components/owners/pages/OwnerCollection.vue'),
  }, {
    path: '/:owner(owners?)/:id(\\d+)/',
    redirect: { name: 'owner-resource-edit' }
  },
  {
    path: '/:owner(owners?)/:id(\\d+)/edit/',
    name: 'owner-resource-edit',
    component: () => import('@/components/owners/pages/OwnerResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'owner-resource-edit-about' }
      }, {
        path: 'about',
        name: 'owner-resource-edit-about',
        component: () => import('@/components/owners/tabs/AboutTab.vue')
      }, {
        path: 'profile',
        name: 'owner-resource-edit-profile',
        component: () => import('@/components/players/tabs/ProfileTab.vue')
      },
    ],
  },

  /* ---------------------------------- WALKERS --------------------------------- */
  {
    path: '/:walker(walkers?)/',
    name: 'walker-collection',
    component: () => import('@/components/walkers/pages/WalkerCollection.vue'),
  }, {
    path: '/:walker(walkers?)/:id(\\d+)/',
    redirect: { name: 'walker-resource-edit' }
  },
  {
    path: '/:walker(walkers?)/:id(\\d+)/edit/',
    name: 'walker-resource-edit',
    component: () => import('@/components/walkers/pages/WalkerResourceEdit.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'walker-resource-edit-about' }
      }, {
        path: 'about',
        name: 'walker-resource-edit-about',
        component: () => import('@/components/walkers/tabs/AboutTab.vue')
      }, {
        path: 'profile',
        name: 'walker-resource-edit-profile',
        component: () => import('@/components/players/tabs/ProfileTab.vue')
      },
    ],
  },

  /* -------------------------------- CATCH ALL ------------------------------- */

  {
    path: '/:unknownUri(.*)*',
    name: 'catch-all',
    component: () => import('@/components/global/pages/CatchAll.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
