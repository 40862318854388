import { defineStore } from 'pinia';

import useMarkApi from '@/stores/api/markApi';
import usePathApi from '@/stores/api/pathApi';
import usePlayerApi from '@/stores/api/playerApi';
import { Mark, Player } from '@/stores/interfaces';

import { useAuthStore } from '@/stores/authStore';
import { usePathStore } from '@/stores/pathStore';

import { useRoute } from 'vue-router';

import { useGlobalStore } from '@/stores/globalStore'

export const useMarkStore = defineStore({

  id: 'markStore',

  /* --------------------------------- STATE -------------------------------- */

  state: () => ({
    route: useRoute(),
    globalStore: useGlobalStore(),
    dirtyOne: false,
    dirtyAll: false,
    marks: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Mark[],
      pagination: {
        total: 0
      }
    },
    mark: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Mark,
    },
    owners: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Player[],
      pagination: {
        total: 0
      }
    },
    owner: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Player,
    },
    walkers: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Player[],
      pagination: {
        total: 0
      }
    },
    walker: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Player,
    },
  }),

  /* --------------------------------- GETTERS -------------------------------- */

  getters: {},

  /* --------------------------------- ACTIONS -------------------------------- */

  actions: {

    /* -------------------------------------------------------------------------- */

    getDistincts(currentRole: string, selector: string, selectorIndex?: string): Promise<string[]> {
      return new Promise<string[]>((resolve) => {
        console.log('getDistincts ' + currentRole + ' @ ' + selector);
        const { getDistincts } = useMarkApi();
        getDistincts(currentRole, selector, selectorIndex)
          .then((response) => resolve(response), (error) => {
            this.globalStore.handleStoreError(error, 'getDistincts')
          })
      })
    },

    /* -------------------------------------------------------------------------- */

    newMark(currentRole: string) {
      console.log('newMark ' + currentRole);
      this.mark.loaded = false
      this.mark.data = {} as Mark // reset before querying
      this.mark.data = {
        'halRole': currentRole,
        'halType': currentRole,
      } as Mark;
      this.mark.loaded = true
      return this.mark
    },

    /* -------------------------------------------------------------------------- */

    createMark(data: Mark, currentRole: string) {
      console.log('createMark ' + currentRole);
      this.mark.created = false
      this.mark.data = {} as Mark // reset before querying
      const { createMark } = useMarkApi();
      createMark(data, currentRole)
        .then((response) => {
          this.mark.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'createMark')
        })
        .finally(() => {
          this.mark.created = true
          const pathStore = usePathStore()
          pathStore.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    updateMark(data: Mark, currentRole: string) {
      console.log('updateMark ' + currentRole + '#' + data.id);
      this.mark.loaded = false
      this.mark.data = {} as Mark // reset before querying
      const { updateMark } = useMarkApi();
      updateMark(data, currentRole)
        .then((response) => {
          this.mark.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile aggiornare le informazioni per ' + data.title + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'updateMark')
        })
        .finally(() => {
          this.dirtyAll = true
          this.dirtyOne = true
          const pathStore = usePathStore()
          pathStore.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    getContextMarks(currentRole: string, include?: string[], query?: any) {
      console.log('getContextMarks ' + currentRole);
      include = include || []
      // DEBUG
      if (this.globalStore.doDebug) if (include.length) console.error([1, include])
      //
      const authStore = useAuthStore();
      if (!authStore.authenticated) return;
      if (
        authStore.isSuperAdmin ||
        authStore.isAdmin
      ) {
        this.getAllMarks(currentRole, include, query);
      } else {
        this.getMarksOf(authStore.role, authStore.id, currentRole, include, query)
      }
    },

    /* -------------------------------------------------------------------------- */

    getAllMarks(currentRole: string, include?: string[], query?: any) {
      console.log('getAllMarks ' + currentRole);
      include = include || []
      // DEBUG
      if (this.globalStore.doDebug) if (include.length) console.error([2, include])
      //
      // reset before querying
      this.marks.loaded = false
      this.marks.data = [] as Mark[]
      this.marks.pagination = {}
      const { getAllMarks } = useMarkApi();
      getAllMarks(currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.marks.data = response.data;
          this.marks.pagination = response.pagination;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getAllMarks')
        })
        .finally(() => {
          this.marks.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    getMarksOf(modelRole: string, modelId: any, currentRole: string, include?: string[], query?: any) {
      console.log('getMarksOf ' + currentRole + ' of ' + modelRole + '#' + modelId);
      include = include || []
      // DEBUG
      if (this.globalStore.doDebug) if (include.length) console.error([3, include])
      //
      // reset before querying
      this.marks.loaded = false
      this.marks.data = [] as Mark[]
      this.marks.pagination = {}
      const { getMarksOf } = useMarkApi();
      getMarksOf(modelRole, modelId, currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.marks.data = response.data;
          this.marks.pagination = response.pagination;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + currentRole + ' di ' + modelRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getMarksOf')
        })
        .finally(() => {
          this.marks.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    deleteMarkByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('deleteMarkByID ' + currentRole + '#' + currentId);
      include = include || []
      // DEBUG
      if (this.globalStore.doDebug) if (include.length) console.error([4, include])
      //
      this.mark.loaded = false
      this.mark.data = {} as Mark // reset before querying
      const { deleteMarkByID } = useMarkApi();
      deleteMarkByID(currentId, currentRole, include)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile cancellare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'deleteMarkByID')
        })
        .finally(() => {
          this.dirtyAll = true
          const pathStore = usePathStore()
          pathStore.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    findMarkByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('findMarkByID ' + currentRole + '#' + currentId);
      include = include || []
      // DEBUG
      if (this.globalStore.doDebug) if (include.length) console.error([5, include])
      //
      this.mark.loaded = false
      this.mark.data = {} as Mark // reset before querying
      const { findMarkByID } = useMarkApi();
      findMarkByID(currentId, currentRole, include)
        .then((response) => {
          this.mark.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'findMarkByID')
        })
        .finally(() => {
          this.mark.loaded = true
          this.dirtyOne = false;
        })
    },

    /* -------------------------------------------------------------------------- */

    unlinkRelatedPlayer(playerRole: string, playerId: any, currentRole: string, currentId: any) {
      console.log('unlinkRelatedPlayer ' + playerRole + '#' + playerId + ' of ' + currentRole + '#' + currentId);
      const { unlinkRelatedPlayer } = useMarkApi();
      unlinkRelatedPlayer(playerRole, playerId, currentRole, currentId)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile eliminare le informazioni per ' + playerRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'unlinkRelatedPlayer')
        })
        .finally(() => {
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    linkRelatedPlayer(playerRole: string, playerId: any, currentRole: string, currentId: any, include?: string[]) {
      console.log('linkRelatedPlayer ' + playerRole + '#' + playerId + ' of ' + currentRole + '#' + currentId);
      include = include || []
      // DEBUG
      if (this.globalStore.doDebug) if (include.length) console.error([6, include])
      //
      // reset before querying
      this[playerRole].linked = false
      this[playerRole].data = [] as Player[]
      const { linkRelatedPlayer } = useMarkApi();
      linkRelatedPlayer(playerRole, playerId, currentRole, currentId, include)
        .then((response) => {
          this[playerRole].data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile collegare le informazioni per ' + playerRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'linkRelatedPlayer')
        })
        .then(() => {
          //
        })
        .finally(() => {
          this[playerRole].linked = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    addCreateRelatedPlayer(playerData: any, playerRole: string, currentRole: string, currentId: any, include?: string[]) {
      console.log('addCreateRelatedPlayer ' + playerRole + ' of ' + currentRole + '#' + currentId);
      include = include || []
      // DEBUG
      if (this.globalStore.doDebug) if (include.length) console.error([7, include])
      //
      // reset before querying
      this[playerRole].created = false
      this[playerRole].data = [] as Player[]
      const { registerPlayer } = usePlayerApi();
      registerPlayer(playerData, playerRole)
        .then((response) => {
          this.linkRelatedPlayer(playerRole, response.id, currentRole, currentId, include)
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + playerRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) {
            this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          }
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'addCreateRelatedPlayer')
        })
        .finally(() => {
          this[playerRole].created = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    linkRelatedPath(pathRole: string, pathId: any, data: Mark, currentRole: string) {
      console.log('linkRelatedPath ' + pathRole + ' of ' + currentRole);
      const { createMark } = useMarkApi();
      createMark(data, currentRole)
        .then((response) => {
          const pathStore = usePathStore()
          pathStore.linkRelatedMark(pathRole, pathId, currentRole, response.id)

        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'createMark')
        })
        .finally(() => {
          //
          const pathStore = usePathStore()
          pathStore.dirtyOne = true
        })

    },

    /* -------------------------------------------------------------------------- */

    unlinkRelatedPath(pathRole: string, pathId: any, currentRole: string, currentId: any) {
      console.log('unlinkRelatedPath ' + pathRole + ' of ' + currentRole + '#' + currentId);
      const { unlinkRelatedMark } = usePathApi();
      unlinkRelatedMark(pathRole, pathId, currentRole, currentId)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile eliminare le informazioni per ' + pathRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'unlinkRelatedPath')
        })
        .finally(() => {
          this.dirtyOne = true
          const pathStore = usePathStore()
          pathStore.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

  },
});
