import axios from 'axios'

import { useGlobalStore } from '@/stores/globalStore'

import usePathApi from '@/stores/api/pathApi'
import usePlayerApi from '@/stores/api/playerApi'
import useNoticeApi from '@/stores/api/noticeApi'

import { Mark, Player } from '@/stores/interfaces'

const globalStore = useGlobalStore()

const pathApi = usePathApi()
const playerApi = usePlayerApi()
const noticeApi = useNoticeApi()

function remapMarkToApi(markData: Mark): object {
  return {
    'id': markData.id,
    'hal_data': {
      //
    },
    'enabled': markData.enabled,
    'category': markData.category,
    'title': markData.title,
    'subtitle': markData.subtitle,
    'description': markData.description,
    'address': markData.address,
    'zip': markData.zip,
    'city': markData.city,
    'province': markData.province,
    'phone': markData.phone,
    'coords': markData.coords,
    'portal_code': markData.portalCode, // Mark.portalCode
    'portal_location': markData.portalLocation, // Mark.portalLocation
    'portal_active': markData.portalActive,
    'portal_for_trails': { ...markData.portalForTrails },
    'stamp_available': markData.stampAvailable, // Mark.stampAvailable
    'stamp_description': markData.stampDescription, // Mark.stampDescription
    'stamp_for_trails': { ...markData.stampForTrails },
    'socials': {
      'website': markData.website,
      'email': markData.email,
      'instagram': markData.instagram,
      'facebook': markData.facebook,
      'tripadvisor': markData.tripadvisor,
      'book_booking': markData.bookBooking,
      'book_airbnb': markData.bookAirbnb,
    },
    'vacancy': markData.vacancy,
    'rating': markData.rating,
    'services': { ...markData.services },
    'offers': markData.offers,
    'is_main': markData.isMain,
    'sequence': markData.sequence,
    'order_column': markData.orderColumn,
  }
}

function remapMarkFromApi(results: any): Mark[] {
  return Object.values(results)
    .filter((item: any) => item.id > 0)
    .map((mark: any) => ({
      id: mark.id,
      halRole: mark.hal_role,
      halType: mark.hal_type,
      halData: mark.hal_data,
      // halData -->
      readingsCount: mark.hal_data?.readings_count || 0,
      // readingsThisMonthCount: mark.hal_data?.readings_this_month_count || 0,
      readingsLastMonthCount: mark.hal_data?.readings_last_month_count || 0,
      // <-- halData
      enabled: mark.enabled,
      category: mark.category,
      title: mark.title,
      subtitle: mark.subtitle,
      description: mark.description,
      address: mark.address,
      zip: mark.zip,
      city: mark.city,
      province: mark.province,
      cityProvince: mark.city_province,
      phone: mark.phone,
      coords: mark.coords,
      portalCode: mark.portal_code,
      portalLocation: mark.portal_location,
      portalActive: mark.portal_active,
      portalForTrails: mark.portal_for_trails,
      stampDescription: mark.stamp_description,
      stampAvailable: mark.stamp_available,
      stampForTrails: mark.stamp_for_trails,
      // socials -->
      website: mark.socials?.website || '',
      email: mark.socials?.email || '',
      instagram: mark.socials?.instagram || '',
      facebook: mark.socials?.facebook || '',
      tripadvisor: mark.socials?.tripadvisor || '',
      bookBooking: mark.socials?.book_booking || '',
      bookAirbnb: mark.socials?.book_airbnb || '',
      // <-- socials
      vacancy: mark.vacancy,
      rating: mark.rating,
      offers: mark.offers,
      isMain: mark.is_main,
      sequence: mark.sequence,
      // services -->
      services: { ...mark.services },// mark.services
      // <-- services
      // utils
      // listOfCategories: mark.list_of_categories,
      // listOfPoiServices: mark.list_of_services,
      orderColumn: mark.order_column,
      // dates
      createdAt: mark.created_at,
      updatedAt: mark.updated_at,
      deletedAt: mark.deleted_at,
      // relationships
      trails: mark.trails ? pathApi.remapPathFromApi(mark.trails) : {},
      owners: mark.owners ? playerApi.remapPlayerFromApi(mark.owners) : {},
      reports: mark.reports ? noticeApi.remapNoticeFromApi(mark.reports) : {},
      advices: mark.advices ? noticeApi.remapNoticeFromApi(mark.advices) : {},
      // media
      images: mark.images,
      files: mark.files,
    })
    )
}

export default function useMarkApi() {

  /* -------------------------------------------------------------------------- */

  function getDistincts(markRole: string, selector: string, selectorIndex?: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + markRole
      // const route = useRoute();
      const dataToApi = {
        params: {
          pluck: selector,
          pluckIndex: selectorIndex
        },
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            resolve(results)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function createMark(markData: Mark, markRole: string): Promise<Mark> {
    return new Promise<Mark>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + markRole + ''
      const dataToApi = remapMarkToApi(markData)
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = remapMarkFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function updateMark(markData: Mark, markRole: string): Promise<Mark> {
    return new Promise<Mark>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + markRole + '/' + markData.id
      const dataToApi = remapMarkToApi(markData)
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = remapMarkFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getAllMarks(markRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + markRole
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby: 'marks.title',
          sortedby: 'asc',
          ...query
        },
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            const data = remapMarkFromApi(results)
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getMarksOf(model: string, id: any, markRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + markRole
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          'orderby_rel': 'title',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            const data = remapMarkFromApi(results)
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function deleteMarkByID(id: any, markRole: string, include?: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + markRole + '/' + id
      const dataToApi = {}
      try {
        axios.delete(url, dataToApi)
          .then(() => {
            resolve(true)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function findMarkByID(id: any, markRole: string, include?: string[]): Promise<Mark> {
    return new Promise<Mark>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + markRole + '/' + id
      const dataToApi = {
        params: {
          include: [''].concat(include).join(',')
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = remapMarkFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function unlinkRelatedPlayer(model: string, id: any, related: string, relatedid: any, include?: string[]): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + related + '/' + relatedid
      const dataToApi = {}
      try {
        axios.delete(url, dataToApi)
          .then(() => {
            const data = {} as Player
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function linkRelatedPlayer(model: string, id: any, related: string, relatedid: any, include?: string[]): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + related + '/' + relatedid
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby_rel: 'fullname',
          sortedby_rel: 'asc',
        }
      }
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = playerApi.remapPlayerFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  return {
    remapMarkToApi,
    remapMarkFromApi,
    //
    getDistincts,
    getAllMarks,
    getMarksOf,
    deleteMarkByID,
    findMarkByID,
    createMark,
    updateMark,
    //
    linkRelatedPlayer,
    unlinkRelatedPlayer,
  }
}
