import { defineStore } from 'pinia';

import useNoticeApi from '@/stores/api/noticeApi';
import { Notice } from '@/stores/interfaces';

import { useAuthStore } from '@/stores/authStore';

import { useRoute } from 'vue-router';

import { useGlobalStore } from '@/stores/globalStore'

export const useNoticeStore = defineStore({

  id: 'noticeStore',

  /* --------------------------------- STATE -------------------------------- */

  state: () => ({
    route: useRoute(),
    globalStore: useGlobalStore(),
    dirtyOne: false,
    dirtyAll: false,
    notices: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Notice[],
      pagination: {
        total: 0
      }
    },
    notice: {
      loaded: false,
      created: false,
      linked: false,
      trasformed: false,
      data: {} as Notice,
    },

  }),

  /* --------------------------------- GETTERS -------------------------------- */

  getters: {},

  /* --------------------------------- ACTIONS -------------------------------- */

  actions: {

    /* -------------------------------------------------------------------------- */

    newNotice(currentRole: string) {
      console.log('newNotice ' + currentRole);
      this.notice.loaded = false
      this.notice.data = {} as Notice // reset before querying
      this.notice.data = {
        'halRole': currentRole,
        'halType': currentRole,
      } as Notice
      this.notice.loaded = true
      return this.notice
    },

    /* -------------------------------------------------------------------------- */

    createNotice(data: Notice, currentRole: string) {
      console.log('createNotice ' + currentRole);
      this.notice.created = false
      this.notice.data = {} as Notice // reset before querying
      const { createNotice } = useNoticeApi();
      createNotice(data, currentRole)
        .then((response) => {
          this.notice.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'createNotice')
        })
        .finally(() => {
          this.notice.created = true
        })
    },

    /* -------------------------------------------------------------------------- */

    updateNotice(data: Notice, currentRole: string) {
      console.log('updateNotice ' + currentRole + '#' + data.id);
      this.notice.loaded = false
      this.notice.data = {} as Notice // reset before querying
      const { updateNotice } = useNoticeApi();
      updateNotice(data, currentRole)
        .then((response) => {
          this.notice.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile aggiornare le informazioni per ' + data.title + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'updateNotice')
        })
        .finally(() => {
          this.dirtyAll = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    getContextNotices(currentRole: string, include?: string[], query?: any) {
      console.log('getContextNotices ' + currentRole);
      include = include || []
      const authStore = useAuthStore();
      if (!authStore.authenticated) return;
      if (
        authStore.isSuperAdmin ||
        authStore.isAdmin
      ) {
        this.getAllNotices(currentRole, include, query)
      } else {
        this.getNoticesOf(authStore.role, authStore.id, currentRole, include, query)
      }
    },


    /* -------------------------------------------------------------------------- */

    getAllNotices(currentRole: string, include?: string[], query?: any) {
      console.log('getAllNotices ' + currentRole);
      include = include || []
      // reset before querying
      this.notices.loaded = false
      this.notices.data = [] as Notice[]
      this.notices.pagination = {}
      const { getAllNotices } = useNoticeApi();
      getAllNotices(currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.notices.data = response.data;
          this.notices.pagination = response.pagination;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getAllNotices')
        })
        .finally(() => {
          this.notices.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    getNoticesOf(modelRole: string, modelId: any, currentRole: string, include?: string[], query?: any) {
      console.log('getNoticesOf ' + currentRole + ' of ' + modelRole + '#' + modelId);
      include = include || []
      // reset before querying
      this.notices.loaded = false
      this.notices.data = [] as Notice[]
      this.notices.pagination = {}
      const { getNoticesOf } = useNoticeApi();
      getNoticesOf(modelRole, modelId, currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.notices.data = response.data;
          this.notices.pagination = response.pagination;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + modelRole + ' di ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getAllNotices')
        })
        .finally(() => {
          this.notices.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    deleteNoticeByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('deleteNoticeByID ' + currentRole + '#' + currentId);
      include = include || []
      this.notice.loaded = false
      this.notice.data = {} as Notice // reset before querying
      const { deleteNoticeByID } = useNoticeApi();
      deleteNoticeByID(currentId, currentRole, include)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile cancellare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'deleteNoticeByID')
        })
        .finally(() => {
          this.dirtyAll = true
        })
    },

    /* -------------------------------------------------------------------------- */

    findNoticeByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('findNoticeByID ' + currentRole + '#' + currentId);
      include = include || []
      this.notice.loaded = false
      this.notice.data = {} as Notice // reset before querying
      const { findNoticeByID } = useNoticeApi();
      findNoticeByID(currentId, currentRole, include)
        .then((response) => {
          this.notice.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'findNoticeByID')
        })
        .finally(() => {
          this.notice.loaded = true
          this.dirtyOne = false;
        })
    },

    /* -------------------------------------------------------------------------- */

    notifyNotice(data: Notice, currentRole: string, language: string) {
      console.log('notifyNotice ' + currentRole + '#' + data.id + ' ' + language);
      this.notice.loaded = false
      this.notice.data = {} as Notice // reset before querying
      const { notifyNotice } = useNoticeApi();
      notifyNotice(data, currentRole, language)
        .then((response) => {
          this.notice.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'notifyNotice')
        })
        .finally(() => {
          this.dirtyAll = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    doTransformReportInAdvice(currentId: any) {
      console.log('doTransformReportInAdvice report #' + currentId);
      this.notice.trasformed = false
      this.notice.data = {} as Notice // reset before querying
      const { doTransformReportInAdvice } = useNoticeApi();
      doTransformReportInAdvice(currentId)
        .then((response) => {
          this.notice.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile modificare le informazioni di ' + 'report' + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'doTransformReportInAdvice')
        })
        .finally(() => {
          this.notice.trasformed = true
        })
    },

    /* -------------------------------------------------------------------------- */

  },
});
