
import axios from 'axios';

import { useGlobalStore } from '@/stores/globalStore'

import usePathApi from '@/stores/api/pathApi';
import useMarkApi from '@/stores/api/markApi';
import useNoticeApi from '@/stores/api/noticeApi';

import { User, Player } from '@/stores/interfaces';

const globalStore = useGlobalStore();

const pathApi = usePathApi()
const markApi = useMarkApi()
const noticeApi = useNoticeApi()

function remapUserToApi(userData: User): object {
  return {
    'id': userData.id,
    'enabled': userData.enabled,
    'name': userData.name,
    'email': userData.email,
    'player_id': userData.playerId,
    'password': userData.password,
  };
}

function remapUserFromApi(results: any[]): User[] {
  return results.map(user => ({
    id: user.id,
    halData: user.hal_data,
    enabled: user.enabled,
    name: user.name,
    email: user.email,
    playerId: user.player_id,
    password: user.password,
    accessToken: user.access_token,
    tokenType: user.token_type
  }));
}

function remapPlayerToApi(playerData: Player): object {
  return {
    'id': playerData.id,
    'hal_data': {
      //
    },
    'enabled': playerData.enabled,
    'title': playerData.title,
    'fullname': playerData.fullname,
    'description': playerData.description,
    'address': playerData.address,
    'zip': playerData.zip,
    'city': playerData.city,
    'province': playerData.province,
    'phone': playerData.phone,
    'socials': {
      'website': playerData.website,
      'email': playerData.email,
      'instagram': playerData.instagram,
      'facebook': playerData.facebook,
      // 'tripadvisor': playerData.tripadvisor,
    },
  };
}

function remapPlayerFromApi(results: any): Player[] {
  return Object.values(results)
    .filter((item: any) => item.id > 0)
    .map((player: any) => ({
      id: player.id,
      halRole: player.hal_role,
      halType: player.hal_type,
      halData: player.hal_data,
      // halData -->
      // <-- halData
      enabled: player.enabled,
      title: player.title,
      fullname: player.fullname,
      description: player.description,
      address: player.address,
      zip: player.zip,
      city: player.city,
      province: player.province,
      phone: player.phone,
      // socials -->
      website: player.socials?.website || '',
      email: player.socials?.email || player.email || '',
      instagram: player.socials?.instagram || '',
      facebook: player.socials?.facebook || '',
      // tripadvisor: player.socials?.tripadvisor || '',
      // <-- socials
      // dates
      createdAt: player.created_at,
      updatedAt: player.updated_at,
      deletedAt: player.deleted_at,
      // relationships
      user: player.user,
      trails: player.trails ? pathApi.remapPathFromApi(player.trails) : {},
      pois: player.pois ? markApi.remapMarkFromApi(player.pois) : {},
      pins: player.pins ? markApi.remapMarkFromApi(player.pins) : {},
      reports: player.reports ? noticeApi.remapNoticeFromApi(player.reports) : {},
      advices: player.advices ? noticeApi.remapNoticeFromApi(player.advices) : {},    // media
      readings: player.readings ? markApi.remapMarkFromApi(player.readings) : {},
      images: player.images,
      files: player.files,
    }));
}

export default function usePlayerApi() {

  /* -------------------------------------------------------------------------- */

  function resetPlayerPassword(username: string): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + 'resetpassword';
      const dataToApi = {
        username: username
      }
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = response.data;
            const data = results;
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }
  /* -------------------------------------------------------------------------- */

  function updatePlayerUser(userData: any, playerRole: string): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + playerRole + '/' + userData.id + '/' + 'user';
      const dataToApi = {
        'id': userData.id,
        'auth': userData.auth,
        'user_id': userData.userId,
        'user_email': userData.userEmail,
        'username': userData.username,
        'password': userData.password,
        'passwordconfirm': userData.passwordconfirm,
      }
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapPlayerFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function createPlayer(playerData: Player, playerRole: string): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + playerRole + '';
      const dataToApi = remapPlayerToApi(playerData)
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapPlayerFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function updatePlayer(playerData: Player, playerRole: string): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + playerRole + '/' + playerData.id;
      const dataToApi = remapPlayerToApi(playerData)
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapPlayerFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getAllPlayers(playerRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + playerRole;
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby: 'players.fullname',
          sortedby: 'asc',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data;
            const data = remapPlayerFromApi(results);
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getPlayersOf(model: string, id: any, playerRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + playerRole;
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          'orderby_rel': 'title',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data;
            const data = remapPlayerFromApi(results);
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function deletePlayerByID(id: any, playerRole: string, include?: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + playerRole + '/' + id;
      const dataToApi = {}
      try {
        axios.delete(url, dataToApi)
          .then(() => {
            resolve(true)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function findPlayerByID(id: any, playerRole: string, include?: string[]): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + playerRole + '/' + id;
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapPlayerFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function registerPlayer(userData: User, playerRole: string): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + playerRole + '/' + 'register' + '';
      const dataToApi = remapUserToApi(userData)
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapPlayerFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  return {
    remapPlayerToApi,
    remapPlayerFromApi,
    remapUserToApi,
    remapUserFromApi,
    //
    getAllPlayers,
    getPlayersOf,
    deletePlayerByID,
    findPlayerByID,
    createPlayer,
    updatePlayer,
    registerPlayer,
    updatePlayerUser,
    resetPlayerPassword,
  };
}
