import { createApp } from 'vue'

import App from '@/App.vue'
import router from '@/router'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import '@/theme/variables.css'

import '@/theme/walkplus.css'

import i18n from '@/i18n/i18n'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueApexCharts from 'vue3-apexcharts'

import { createPinia } from 'pinia'

import { defineCustomElements } from "@ionic/pwa-elements/loader"

import { useAuthStore } from '@/stores/authStore'

declare global {
  interface String {
    toProperCase(): string
    toFirstCase(): string
  }
}

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1) }) // .toLowerCase()
}

String.prototype.toFirstCase = function () {
  return this.charAt(0).toUpperCase() + this.substr(1)
}


/* ------------------------------- CSRF-TOKEN > ------------------------------- */

const app = createApp(App)
  .use(i18n)
  .use(VueAxios, axios)
  .use(IonicVue)
  .use(createPinia())
  .use(router)
  .use(VueApexCharts)

const authStore = useAuthStore()

axios.defaults.withCredentials = true
// axios.defaults.headers.common['X-SET-LANGUAGE'] = i18n.global.locale.value

router.beforeEach((to, from, next) => {
  if ((!authStore.authenticated) && ([
    'home-page',
    'login',
    'setpassword',
    'resetpassword',
  ].indexOf(to.name as string) == -1)) {
    next({ name: 'login', replace: true })
  } else next()
})

router
  .isReady()
  .then(() => {
    authStore.getMe().then(() => {
      app.mount('#app')
      defineCustomElements(window)
    })
  })

/* -------------------------------------------------------------------------- */

// import authStore from '@/stores/authStore';

// router.beforeEach((to) => {
//   // ✅ This will work because the router starts its navigation after pinia is installed
//   const authStore = useAuthStore()

//   if (to.meta.requiresAuth && !authStore.isLoggedIn) return '/login'
// })

/* -------------------------------------------------------------------------- */
