import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f36412a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (p, i) => {
      return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
        key: i,
        "auto-hide": "false"
      }, {
        default: _withCtx(() => [
          (_ctx.authStore.can[p.showIfCan] || !p.showIfCan)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 0,
                button: "",
                onClick: _withModifiers(($event: any) => (_ctx.$router.push(p.url)), ["stop"]),
                lines: "none",
                detail: "false",
                class: _normalizeClass(["hydrated", { selected: _ctx.$route.fullPath.indexOf(p.url) == 0 }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    color: "dark",
                    src: _ctx.globalStore.getIcon(p.iosIcon),
                    style: _normalizeStyle({
            // transform: p.isOpen ? 'rotate(' + rotateBy + 'deg)' : 'rotate(0deg)',
          }),
                    class: _normalizeClass('menuarrow chevron_' + p.key),
                    name: "arrow-forward-circle-outline"
                  }, null, 8, ["src", "style", "class"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t(p.title)), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick", "class"]))
            : _createCommentVNode("", true),
          (p.children)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(p.children, (ch) => {
                  return (_openBlock(), _createElementBlock("div", { key: ch }, [
                    (_ctx.authStore.can[ch.showIfCan] || !ch.showIfCan)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 0,
                          button: "",
                          onClick: _withModifiers(($event: any) => (_ctx.$router.push(ch.url)), ["stop"]),
                          lines: "none",
                          detail: "false",
                          class: _normalizeClass([[p.key, { selected: _ctx.$route.fullPath.indexOf(ch.url) == 0 }], "hydrated submenu ion-padding-start"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t(ch.title)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "class"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_ion_item, {
      lines: "none",
      detail: "false"
    })
  ]))
}