import axios from 'axios';

import { useGlobalStore } from '@/stores/globalStore'

import usePathApi from '@/stores/api/pathApi';
import usePlayerApi from '@/stores/api/playerApi';
import useMarkApi from '@/stores/api/markApi';

import { Notice } from '@/stores/interfaces';

const globalStore = useGlobalStore();

const pathApi = usePathApi()
const playerApi = usePlayerApi()
const markApi = useMarkApi()

function remapNoticeToApi(noticeData: Notice): object {
  return {
    'id': noticeData.id,
    'hal_data': {
      //
    },
    'hal_type': noticeData.category,
    'notified': noticeData.notified,
    'enabled': noticeData.enabled,
    'level': noticeData.level,
    'reason': noticeData.reason,
    'title': noticeData.title,
    'body': noticeData.body,
    'coords': noticeData.coords,
    'accuracy': noticeData.accuracy,
    'when': noticeData.when,
    'hasTranslation': noticeData.hasTranslation,
  };
}

function remapNoticeFromApi(results: any): Notice[] {
  return Object.values(results)
    .filter((item: any) => item.id > 0)
    .map((notice: any) => ({
      id: notice.id,
      halRole: notice.hal_role,
      halType: notice.hal_type,
      halData: notice.hal_data,
      category: notice.hal_type,
      notified: notice.notified,
      enabled: notice.enabled,
      level: notice.level,
      title: notice.title,
      reason: notice.reason,
      body: notice.body,
      coords: notice.coords,
      accuracy: notice.accuracy,
      when: notice.when,
      hasTranslation: notice.hasTranslation,
      // dates
      createdAt: notice.created_at,
      updatedAt: notice.updated_at,
      deletedAt: notice.deleted_at,
      // relations
      who: notice.who ? playerApi.remapPlayerFromApi([notice.who])[0] : {},
      trail: notice.trail ? pathApi.remapPathFromApi([notice.trail])[0] : {},
      poi: notice.poi ? markApi.remapMarkFromApi([notice.poi])[0] : {},
    }))
}

export default function useNoticeApi() {

  /* -------------------------------------------------------------------------- */

  function doTransformReportInAdvice(id: any): Promise<Notice> {
    return new Promise<Notice>((resolve, reject) => {
      const noticeRole = 'report'
      const url = globalStore.apiBaseUrl + '/' + noticeRole + '/' + id + '/to-advice';
      const dataToApi = {}
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapNoticeFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function createNotice(noticeData: Notice, noticeRole: string): Promise<Notice> {
    return new Promise<Notice>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + noticeRole + '';
      const dataToApi = remapNoticeToApi(noticeData)
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapNoticeFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function updateNotice(noticeData: Notice, noticeRole: string): Promise<Notice> {
    return new Promise<Notice>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + noticeRole + '/' + noticeData.id;
      const dataToApi = remapNoticeToApi(noticeData)
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapNoticeFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getAllNotices(noticeRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + noticeRole;
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby: 'notices.when',
          sortedby: 'desc',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data;
            const data = remapNoticeFromApi(results);
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getNoticesOf(model: string, id: any, noticeRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + noticeRole;
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          'orderby_rel': 'when',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data;
            const data = remapNoticeFromApi(results);
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function deleteNoticeByID(id: any, noticeRole: string, include?: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + noticeRole + '/' + id;
      const dataToApi = {}
      try {
        axios.delete(url, dataToApi)
          .then(() => {
            resolve(true)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function findNoticeByID(id: any, noticeRole: string, include?: string[]): Promise<Notice> {
    return new Promise<Notice>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + noticeRole + '/' + id;
      const dataToApi = {
        params: {
          include: [''].concat(include).join(',')
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = [response.data];
            const data = remapNoticeFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }


  /* -------------------------------------------------------------------------- */

  function notifyNotice(noticeData: Notice, noticeRole: string, language: string): Promise<Notice> {
    return new Promise<Notice>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + noticeRole + '/' + noticeData.id;
      const dataToApi = remapNoticeToApi(noticeData)
      try {
        axios.patch(url, {
          ...dataToApi, ...{ send_language: language }
        })
          .then(response => {
            const results = [response.data];
            const data = remapNoticeFromApi(results)[0];
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          });
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }
  /* -------------------------------------------------------------------------- */

  return {
    remapNoticeToApi,
    remapNoticeFromApi,
    //
    getAllNotices,
    getNoticesOf,
    deleteNoticeByID,
    findNoticeByID,
    createNotice,
    updateNotice,
    doTransformReportInAdvice,
    notifyNotice,
  };
}
