import { defineStore } from 'pinia';

import usePlayerApi from '@/stores/api/playerApi';
import { Player } from '@/stores/interfaces';

import { useAuthStore } from '@/stores/authStore';

import { useRoute } from 'vue-router';

import { useGlobalStore } from '@/stores/globalStore'

export const usePlayerStore = defineStore({

  id: 'playerStore',

  /* --------------------------------- STATE -------------------------------- */

  state: () => ({
    route: useRoute(),
    globalStore: useGlobalStore(),
    dirtyOne: false,
    dirtyAll: false,
    players: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Player[],
      pagination: {
        total: 0
      }
    },
    player: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Player,
    },
    links: {
      loaded: false,
      when: 0,
      source: '',
      data: [] as Player[],
      pagination: {
        total: 0
      }
    },
    link: {
      loaded: false,
      created: false,
      linked: false,
      data: {} as Player,
    },
  }),

  /* --------------------------------- GETTERS -------------------------------- */

  getters: {},

  /* --------------------------------- ACTIONS -------------------------------- */

  actions: {

    /* -------------------------------------------------------------------------- */

    resetPlayerPassword(username: string) {
      console.log('resetPlayerPassword ' + username);
      const { resetPlayerPassword } = usePlayerApi();
      resetPlayerPassword(username)
        .then((response) => {
          response;
          this.globalStore.handleToast('Richiesta di nuove credenziali inviata con successo.')
          this.globalStore.handleAlert('Controlla la tua casella email "' + username + '", riceverai una email contenente tutte le indicazioni per ripristinare le tue credenziali di accesso.', false)
        }, (error) => {
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'resetPlayerPassword')
        })
        .finally(() => {
          //
        })
    },
    /* -------------------------------------------------------------------------- */

    updatePlayerUser(data: any, currentRole: string) {
      console.log('updatePlayerUser ' + currentRole + '#' + data.id);
      this.player.loaded = false
      this.player.data = {} as Player // reset before querying
      const { updatePlayerUser } = usePlayerApi();
      updatePlayerUser(data, currentRole)
        .then((response) => {
          this.player.data = response;
          this.globalStore.handleToast('Informazioni salvate con successo.')
        }, (error) => {
          // this.globalStore.handleToast('Impossibile salvare le credenziali... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'updatePlayer')
        })
        .finally(() => {
          this.dirtyAll = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    newPlayer(currentRole: string) {
      console.log('newPlayer ' + currentRole);
      this.player.loaded = false
      this.player.data = {} as Player // reset before querying
      this.player.data = {
        'halRole': currentRole,
        'halType': currentRole,
      } as Player;
      this.player.loaded = true
    },

    /* -------------------------------------------------------------------------- */

    createPlayer(data: Player, currentRole: string) {
      console.log('createPlayer ' + currentRole);
      this.player.created = false
      this.player.data = {} as Player // reset before querying
      const { createPlayer } = usePlayerApi();
      createPlayer(data, currentRole)
        .then((response) => {
          this.player.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile creare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'createPlayer')
        })
        .finally(() => {
          this.player.created = true
        })
    },

    /* -------------------------------------------------------------------------- */

    updatePlayer(data: Player, currentRole: string) {
      console.log('updatePlayer ' + currentRole + '#' + data.id);
      this.player.loaded = false
      this.player.data = {} as Player // reset before querying
      const { updatePlayer } = usePlayerApi();
      updatePlayer(data, currentRole)
        .then((response) => {
          this.player.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile aggiornare le informazioni per ' + data.fullname + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'updatePlayer')
        })
        .finally(() => {
          this.dirtyAll = true
          this.dirtyOne = true
        })
    },

    /* -------------------------------------------------------------------------- */

    getContextPlayers(currentRole: string, include?: string[], query?: any) {
      console.log('getContextPlayers ' + currentRole);
      include = include || []
      const authStore = useAuthStore();
      if (!authStore.authenticated) return;
      if (
        authStore.isSuperAdmin ||
        authStore.isAdmin
      ) {
        this.getAllPlayers(currentRole, include, query);
      } else {
        this.getPlayersOf(authStore.role, authStore.id, currentRole, include, query)
      }
    },

    /* -------------------------------------------------------------------------- */

    getAllPlayers(currentRole: string, include?: string[], query?: any) {
      console.log('getAllPlayers ' + currentRole);
      include = include || []
      // reset before querying
      this.players.loaded = false
      this.players.data = [] as Player[] // reset before querying
      this.players.pagination = {}
      const { getAllPlayers } = usePlayerApi();
      getAllPlayers(currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.players.data = response.data;
          this.players.pagination = response.pagination;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + currentRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getAllPlayers')
        })
        .finally(() => {
          this.players.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    getPlayersOf(modelRole: string, modelId: any, currentRole: string, include?: string[], query?: any) {
      console.log('getPlayersOf ' + currentRole + ' of ' + modelRole + '#' + modelId);
      include = include || []
      // reset before querying
      this.players.loaded = false
      this.players.data = [] as Player[] // reset before querying
      this.players.pagination = {}
      const { getPlayersOf } = usePlayerApi();
      getPlayersOf(modelRole, modelId, currentRole, include, this.globalStore.query(this.route, query))
        .then((response: any) => {
          this.players.data = response.data;
          this.players.pagination = response.pagination;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni per ' + currentRole + ' di ' + modelRole + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'getPlayersOf')
        })
        .finally(() => {
          this.players.loaded = true
          this.dirtyAll = false
        })
    },

    /* -------------------------------------------------------------------------- */

    deletePlayerByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('deletePlayerByID ' + currentRole + '#' + currentId);
      include = include || []
      this.player.loaded = false
      this.player.data = {} as Player // reset before querying
      const { deletePlayerByID } = usePlayerApi();
      deletePlayerByID(currentId, currentRole, include)
        .then(() => {
          //
        }, (error) => {
          // this.globalStore.handleToast('Impossibile cancellare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'deletePlayerByID')
        })
        .finally(() => {
          this.dirtyAll = true
        })
    },

    /* -------------------------------------------------------------------------- */

    findPlayerByID(currentId: any, currentRole: string, include?: string[]) {
      console.log('findPlayerByID ' + currentRole + '#' + currentId);
      include = include || []
      this.player.loaded = false
      this.player.data = {} as Player // reset before querying
      const { findPlayerByID } = usePlayerApi();
      findPlayerByID(currentId, currentRole, include)
        .then((response) => {
          this.player.data = response;
        }, (error) => {
          // this.globalStore.handleToast('Impossibile recuperare le informazioni di ' + currentRole + ' #' + currentId + '... (' + error.toString() + ')', true)
          if (this.globalStore.doDebug) this.globalStore.handleToast(error.response?.data?.debug || error.response?.data?.error || error.response?.data?.message, true)
          this.globalStore.handleAlert(error.response?.data?.error || error.response?.data?.message || '(errore non determinato)', true)
          this.globalStore.handleStoreError(error, 'findPlayerByID')
        })
        .finally(() => {
          this.player.loaded = true
          this.dirtyOne = false;
        })
    },

    /* -------------------------------------------------------------------------- */

  },
});
