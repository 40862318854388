
import { useI18n } from 'vue-i18n';

import {
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
  createAnimation,
} from '@ionic/vue';

import { defineComponent } from 'vue';

import { useRouter } from 'vue-router';

import { useGlobalStore } from '@/stores/globalStore';

import appPages from '@/data/menus';

import { useAuthStore } from '@/stores/authStore';
import { usePathStore } from '@/stores/pathStore';
import { useMarkStore } from '@/stores/markStore';
import { useNoticeStore } from '@/stores/noticeStore';

export default defineComponent({
  name: 'NavigationMenu',
  components: {
    IonIcon,
    IonItem,
    IonLabel,
    IonMenuToggle,
  },
  setup() {
    console.log('NavigationMenu');
    const router = useRouter();
    // lang
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const authStore = useAuthStore();
    const pathStore = usePathStore();
    const markStore = useMarkStore();
    const noticeStore = useNoticeStore();
    // globalStore
    const globalStore = useGlobalStore();
    // const selected = ref(0);
    // const rotateBy = ref(60);
    return {
      t,
      appPages,
      globalStore,
      authStore,
      pathStore,
      markStore,
      noticeStore,
      router,
      // selected,
      // rotateBy,
    };
  },
  methods: {
    toggleMenu: function(
      menu: {
        isOpen: boolean;
        key: string;
      }
      // i: number
    ) {
      const from = menu.isOpen ? '40px' : '0px';
      const to = menu.isOpen ? '0px' : '40px';
      const toggleLevel = createAnimation()
        .addElement(document.querySelectorAll('.' + menu.key))
        .duration(300)
        .fromTo('height', from, to);
      toggleLevel.play();
      // const fromRotate = menu.isOpen
      //   ? 'rotate(' + this.rotateBy + 'deg)'
      //   : 'rotate(0deg)';
      // const toRotate = menu.isOpen
      //   ? 'rotate(0deg)'
      //   : 'rotate(' + this.rotateBy + 'deg)';
      // const rotateIcon = createAnimation()
      //   .addElement(document.querySelectorAll('.chevron_' + menu.key))
      //   .duration(300)
      //   .fromTo('transform', fromRotate, toRotate);
      // rotateIcon.play();
      menu.isOpen = !menu.isOpen;
    },
  },
});
