import axios from 'axios'

import { useGlobalStore } from '@/stores/globalStore'

import useNoticeApi from '@/stores/api/noticeApi'
import useMarkApi from '@/stores/api/markApi'
import usePlayerApi from '@/stores/api/playerApi'

import { Path, Mark, Player, Notice } from '@/stores/interfaces'

const globalStore = useGlobalStore()

const playerApi = usePlayerApi()
const markApi = useMarkApi()
const noticeApi = useNoticeApi()

function remapPathToApi(pathData: Path): object {
  return {
    'id': pathData.id,
    'hal_type': pathData.halType,
    'hal_data': {
      'steps_foot_count': pathData.stepsFootCount,
      'steps_mtb_count': pathData.stepsMtbCount,
      'manager': {
        'name': pathData.managerName,
        'phone': pathData.managerPhone,
        'address': pathData.managerAddress,
        'email': pathData.managerEmail,
        'zip': pathData.managerZip,
        'city': pathData.managerCity,
        'province': pathData.managerProvince,
        'website': pathData.managerWebsite,
      },
      // "info_in_app": pathData.infoInApp,
    },
    "info_in_app": pathData.infoInApp,
    'enabled': pathData.enabled,
    'visible': pathData.visible,
    'title': pathData.title,
    'description': pathData.description,
    'start': pathData.start,
    'start_coords': pathData.startCoords,
    'finish': pathData.finish,
    'finish_coords': pathData.finishCoords,
    'distance': pathData.distance,
    'uplevel': pathData.uplevel,
    'downlevel': pathData.downlevel,
    'order_column': pathData.orderColumn,
  }
}

function remapPathFromApi(results: any): Path[] {
  return Object.values(results)
    .filter((item: any) => item.id > 0)
    .map((path: any) => ({
      id: path.id,
      halRole: path.hal_role,
      halType: path.hal_type,
      halData: path.hal_data,
      // halData -->
      poisCount: path.hal_data?.pois_count || 0,
      reportsCount: path.hal_data?.reports_count || 0,
      advicesCount: path.hal_data?.advices_count || 0,
      stepsFootCount: path.hal_data?.steps_foot_count || 0,
      stepsMtbCount: path.hal_data?.steps_mtb_count || 0,

      nfcsActiveCount: path.hal_data?.nfcs_active_count || 0,
      walkersCount: path.hal_data?.walkers_count || 0,
      // walkersThisMonthCount: path.hal_data?.walkers_this_month_count || 0,
      // walkersLastMonthCount: path.hal_data?.walkers_last_month_count || 0,
      readingsCount: path.hal_data?.readings_count || 0,
      // readingsThisMonthCount: path.hal_data?.readings_this_month_count || 0,
      // readingsLastMonthCount: path.hal_data?.readings_last_month_count || 0,

      timeMedia: path.hal_data?.time_media || 0,
      speedMedia: path.hal_data?.speed_media || 0,
      managerName: path.hal_data?.manager?.name || '',
      managerPhone: path.hal_data?.manager?.phone || '',
      managerAddress: path.hal_data?.manager?.address || '',
      managerEmail: path.hal_data?.manager?.email || '',
      managerZip: path.hal_data?.manager?.zip || '',
      managerCity: path.hal_data?.manager?.city || '',
      managerProvince: path.hal_data?.manager?.province || '',
      managerWebsite: path.hal_data?.manager?.website || '',
      // <-- halData
      infoInApp: path.info_in_app || path.hal_data?.info_in_app || '',
      enabled: path.enabled,
      visible: path.visible,
      title: path.title,
      description: path.description,
      start: path.start,
      startCoords: path.start_coords,
      finish: path.finish,
      finishCoords: path.finish_coords,
      distance: path.distance,
      uplevel: path.uplevel,
      downlevel: path.downlevel,
      orderColumn: path.order_column,
      // dates
      createdAt: path.created_at,
      updatedAt: path.updated_at,
      deletedAt: path.deleted_at,
      // relationships
      steps: path.steps ? markApi.remapMarkFromApi(path.steps) : {},
      places: path.places ? markApi.remapMarkFromApi(path.places) : {},
      links: path.links ? markApi.remapMarkFromApi(path.links) : {},
      pois: path.pois ? markApi.remapMarkFromApi(path.pois) : {},
      towns: path.towns || {},
      poisByTowns: path.poisByTowns || {},
      walkers: path.walkers ? playerApi.remapPlayerFromApi(path.walkers) : {},
      managers: path.managers ? playerApi.remapPlayerFromApi(path.managers) : {},
      reports: path.reports ? noticeApi.remapNoticeFromApi(path.reports) : {},
      advices: path.advices ? noticeApi.remapNoticeFromApi(path.advices) : {},
      // media
      images: path.images,
      files: path.files,
    }))
}

export default function usePathApi() {

  /* -------------------------------------------------------------------------- */

  function getDistinctsOf(model: string, id: any, pathRole: string, selector: string, selectorIndex?: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + pathRole
      // const route = useRoute();
      const dataToApi = {
        params: {
          pluck: selector,
          pluckIndex: selectorIndex
        },
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            resolve(results)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }


  /* -------------------------------------------------------------------------- */

  function getDistincts(pathRole: string, selector: string, selectorIndex?: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + pathRole
      // const route = useRoute();
      const dataToApi = {
        params: {
          pluck: selector,
          pluckIndex: selectorIndex
        },
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            resolve(results)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function createPath(pathData: Path, pathRole: string): Promise<Path> {
    return new Promise<Path>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + pathRole + ''
      const dataToApi = remapPathToApi(pathData)
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = remapPathFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function updatePath(pathData: Path, pathRole: string): Promise<Path> {
    return new Promise<Path>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + pathData.id
      const dataToApi = remapPathToApi(pathData)
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = remapPathFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getAllPaths(pathRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + pathRole
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby: 'paths.order_column',
          sortedby: 'asc',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            const data = remapPathFromApi(results)
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getPathsOf(model: string, id: any, pathRole: string, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + pathRole
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          'orderby_rel': 'title',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            const data = remapPathFromApi(results)
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function deletePathByID(id: any, pathRole: string, include?: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + id
      const dataToApi = {}
      try {
        axios.delete(url, dataToApi)
          .then(() => {
            resolve(true)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function findPathByID(id: any, pathRole: string, include?: string[]): Promise<Path> {
    return new Promise<Path>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + id
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = remapPathFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getRelatedMarks(id: any, pathRole: string, related: any, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + id + '/' + related
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          // 'orderby_rel': 'title',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            const data = markApi.remapMarkFromApi(results)
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function addRelatedMark(id: any, pathRole: string, related: any, data: object): Promise<Mark> {
    return new Promise<Mark>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + id + '/' + related
      const dataToApi = data
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = markApi.remapMarkFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function unlinkRelatedMark(model: string, id: any, related: string, relatedid: any, include?: string[]): Promise<Mark> {
    return new Promise<Mark>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + related + '/' + relatedid
      const dataToApi = {}
      try {
        axios.delete(url, dataToApi)
          .then(() => {
            const data = {} as Mark
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function linkRelatedMark(model: string, id: any, related: string, relatedid: any, include?: string[]): Promise<Mark> {
    return new Promise<Mark>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + related + '/' + relatedid
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby_rel: 'title',
          sortedby_rel: 'asc',
        }
      }
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = markApi.remapMarkFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getRelatedPlayers(id: any, pathRole: string, related: any, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + id + '/' + related
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby_rel: 'fullname',
          sortedby_rel: 'asc',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            const data = playerApi.remapPlayerFromApi(results)
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function unlinkRelatedPlayer(model: string, id: any, related: string, relatedid: any, include?: string[]): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + related + '/' + relatedid
      const dataToApi = {}
      try {
        axios.delete(url, dataToApi)
          .then(() => {
            const data = {} as Player
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function linkRelatedPlayer(model: string, id: any, related: string, relatedid: any, include?: string[]): Promise<Player> {
    return new Promise<Player>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + model + '/' + id + '/' + related + '/' + relatedid
      const dataToApi = {
        params: {
          include: [''].concat(include).join(','),
          orderby_rel: 'fullname',
          sortedby_rel: 'asc',
        }
      }
      try {
        axios.patch(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = playerApi.remapPlayerFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function getRelatedNotices(id: any, pathRole: string, related: any, include?: string[], query?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      include = include || []
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + id + '/' + related
      const dataToApi = {
        params: {
          include: ['who'].concat(include).join(','),
          orderby_rel: 'when',
          sortedby_rel: 'desc',
          ...query
        }
      }
      try {
        axios.get(url, dataToApi)
          .then(response => {
            const results = response.data
            const data = noticeApi.remapNoticeFromApi(results)
            const pagination = results?.meta?.pagination
            resolve({ data: data, pagination: pagination })
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  function addRelatedNotice(id: any, pathRole: string, related: any, data: object): Promise<Notice> {
    return new Promise<Notice>((resolve, reject) => {
      const url = globalStore.apiBaseUrl + '/' + pathRole + '/' + id + '/' + related
      const dataToApi = data
      try {
        axios.post(url, dataToApi)
          .then(response => {
            const results = [response.data]
            const data = noticeApi.remapNoticeFromApi(results)[0]
            resolve(data)
          }, error => {
            reject(error)
            globalStore.handleApiError(error, url)
          })
      } catch (error) {
        reject(error)
        globalStore.handleApiError(error, url)
      }
    })
  }

  /* -------------------------------------------------------------------------- */

  return {
    remapPathToApi,
    remapPathFromApi,
    //
    getDistincts,
    getDistinctsOf,
    getAllPaths,
    getPathsOf,
    deletePathByID,
    findPathByID,
    createPath,
    updatePath,
    //
    getRelatedMarks,
    addRelatedMark,
    linkRelatedMark,
    unlinkRelatedMark,
    //
    getRelatedPlayers,
    linkRelatedPlayer,
    unlinkRelatedPlayer,
    //
    getRelatedNotices,
    addRelatedNotice,
  }
}
