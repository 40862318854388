import { alertController, toastController } from '@ionic/vue'

import { computed, ref, Ref } from 'vue'

import { helpers } from '@vuelidate/validators'

import axios from 'axios'

import moment from 'moment'

import i18n from '@/i18n/i18n'
import router from '@/router'

const loading = ref(true)

const getAsBoolean = (envBool: any) => !envBool || !!(envBool == 'true')

const doDebug = getAsBoolean(process.env.VUE_APP_DEBUG) || false

const testNewFeatures = getAsBoolean(process.env.VUE_APP_TEST_NEW_FEATURE) || false

const defaultCoordinates = '44.638330,10.903034'.split(',')// '10.903034,44.638330'

const timestamp = computed(() => Date.now() / 1000)

const apiHost = process.env.VUE_APP_API_HOST

const apiBaseUrl = apiHost + process.env.VUE_APP_API_PFX

const imgPlaceholder = process.env.BASE_URL + 'assets/no_image.svg'

const listOfPoiServices = ref()
const listOfPoiCategories = ref()
const listOfNoticeCategories = ref()

const colors = ref()

const languages = ref([] as string[])

const lang4contents = i18n.global.locale.value

const months = { 1: "Gen", 2: "Feb", 3: "Mar", 4: "Apr", 5: "Mag", 6: "Giu", 7: "Lug", 8: "Ago", 9: "Set", 10: "Ott", 11: "Nov", 12: "Dic", }

const translatables = {
  'notice': [
    'title',
    'body',
  ],
  'path': [
    'title',
    'description',
  ],
  'mark': [
    'subtitle',
    'description',
    'portalLocation',
    'offers',
  ]
} as Record<string, any>

const setTranslatables = (model: string, instance: Ref) => {
  (Object.keys(instance.value as Record<string, any>) || []).forEach(
    (column: any) => {
      if (translatables[model].indexOf(column) > -1) {
        const label = Array.from(document.getElementsByClassName('trans-' + column))[0]
        if (typeof label == 'object') {
          const template = document.createElement('ion-icon')
          template.setAttribute(':icon', 'languageOutline')
          template.setAttribute(':color', 'light')
          label.appendChild(template)
        }
      }
    }
  )
}

const coordsRule = (val: any) => !helpers.req(val) || (
  /^\s*-?\s*[+-]?(([1-8]?[0-9])(\.[0-9]{1,15})?|90(\.0{1,15})?)\s*,\s*[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,15})?)|180(\.0{1,15})?)\s*$/i.test(
    val)
)

const sometimesRule = (val: any) => {
  return !helpers.req(val) || (val.toString().trim().length > 0)
}

const handleToast = (message: string, isError = false) => {
  toastController
    .create({
      message: message,
      position: "top",
      color: isError ? "danger" : "light",
      duration: 2000,
    })
    .then((t) => t.present())
}

async function handleAlert(message: string, isError = false) {
  const alert = await alertController
    .create({
      cssClass: 'my-custom-class',
      header: isError ? 'Errore' : 'Avviso',
      // subHeader: 'DEV STUFF',
      message: message,
      buttons: [{
        text: 'OK',
        role: 'ok',
      }],
    })
  await alert.present()
  const { role } = await alert.onDidDismiss()
  return role == 'ok'

  // return true
}

async function handleConfirm(message: string) {
  const confirm = await alertController
    .create({
      cssClass: 'my-custom-class',
      header: 'Attenzione',
      // subHeader: 'DEV STUFF',
      message: message,
      buttons: [{
        text: 'OK',
        role: 'ok',
      }, {
        text: 'Cancel',
        role: 'cancel',
      }],
    })
  await confirm.present()
  const { role } = await confirm.onDidDismiss()
  return role == 'ok'
}

const getIcon = (icon: string): string => process.env.BASE_URL + 'assets/icon/' + icon + '.svg'

const handleApiError = (err: any, messages?: any) => {
  if (err.toString().indexOf('401') != -1) {
    router.replace({ name: "login" })
    router.go(0)
  } else {
    console.error([err, messages])
    // console.error([messages.toString(), err.toString()])
  }
  return
}

const handleStoreError = (err: any, messages?: any) => {
  if (err.toString().indexOf('401') != -1) {
    router.replace({ name: "login" })
    router.go(0)
  } else {
    console.error([messages.toString(), err])
  }
}

const loadEnvironment = async () => {
  const locales = require.context('@/i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale: string = matched[1]
      languages.value.push(locale)
    }
  })
  const url = apiBaseUrl + '/environment'
  await axios.get(url)
    .then((response) => {
      // ROLES,
      // ALL_MODELS,
      // Q_IMAGE_SCOPES,
      // NOTICE_CATEGORY,
      listOfNoticeCategories.value = response.data.NOTICE_REASON_CATEGORY
      // JOURNAL_TAP_CATEGORY,
      listOfPoiServices.value = response.data.MARK_POI_SERVICES
      listOfPoiCategories.value = response.data.MARK_POI_CATEGORY

      colors.value = response.data.COLORS
    })
    .catch((err) => {
      handleApiError(err, 'Cannot read environment...')
    })
}

const setOrder = async (event: CustomEvent, instance: any,
  model: string) => {
  event.detail.complete(instance)
  const sorted = Array.from(instance)
    .map(function (item: any) {
      return item.id
    })
  const url = apiBaseUrl + '/' + model + '/' + 'reorder'
  const dataToApi = {
    'ids': sorted
  }
  await axios.patch(url, dataToApi)
    .then(() => {
      //
    })
    .catch((err) => {
      handleApiError(err, 'Cannot read environment...')
    })
}

function timeAgo(when: any, format?: any): any {
  return moment
    .unix(when)
    .format(format || 'DD/MM/YYYY HH:mm')
}

function getValueByKey(object: any, value: any): any {
  const keys = Object.keys(object)
  const values = Object.values(object)
  return values[keys.indexOf(value)]
}

function getKeyByValue(object: any, value: any): any {
  const keys = Object.keys(object)
  const values = Object.values(object)
  let label = ''
  values.forEach((children: any, index: any): any => {
    const found = children.find((val: any) => val == value)
    if (found) label = keys[index]
    return found
  })
  return label
}

const noticeFiltersForFiltering = () => Object.entries(listOfNoticeCategories.value).map(
  (item: any) => {
    const entries = new Map([
      [item[1], item[1]]
    ])
    return Object.fromEntries(entries)
  })


const poiFilters = {
  "pinpoint": [
    "pinpoint",
  ],
  "visit": [
    "location",
  ],
  "rest": [
    "restaurant",
    "bar",
    "water",
  ],
  "stay": [
    "staying",
    "camping",
  ],
  "shop": [
    "shop",
    "pharmacy",
  ],
  "service": [
    "parking",
    "service",
    "hospital",
  ],
}

const poiFiltersForFiltering = () => Object.entries(poiFilters).map(
  (item: any) => {
    const entries = new Map([
      [item[1].join(','), item[0]]
    ])
    return Object.fromEntries(entries)
  })


const trailFilters = ref({})

const trailFiltersForFiltering = () => Object.entries(trailFilters.value).map(
  (item: any) => {
    const entries = new Map([
      [item[0], item[1]]
    ])
    return Object.fromEntries(entries)
  })

const showCategory = (value: string, t: Function) => {
  const label = getKeyByValue(poiFilters, value)
  return (
    '<ion-badge class="poi-' +
    label +
    ' wkp-text-smallest wkp-text-white wkp-line-height-12 py-4">' +
    t('menu.poi.' + label) +
    '</ion-badge>'
  )
}


const query = (route: any, query?: any) => {
  const q = {
    search: [route.query?.search, query?.search].filter((item: any) => item?.trim()).join(';'),
    page: query?.page || route.query?.page || 1,
    limit: typeof query?.limit == 'undefined' ? (typeof route.query?.limit == 'undefined' ? process.env.VUE_APP_PER_PAGE : route.query?.limit) : query.limit
  }
  return q
}

function getSeriesOf(currentRole: string, currentId: number, by: string, year?: number): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const url = apiBaseUrl + '/' + currentRole + '/' + currentId + '/series'
    const dataToApi = {
      params: {
        by: by,
        year: year,
      }
    }
    try {
      axios.get(url, dataToApi)
        .then(response => {
          const results = response.data
          resolve(results)
        }, error => {
          reject(error)
          handleApiError(error, url)
        })
    } catch (error) {
      reject(error)
      handleApiError(error, url)
    }
  })
}

const load = () => {
  loadEnvironment()
}

load()

export const useGlobalStore = () => ({
  loading,
  doDebug,
  testNewFeatures,
  timestamp,
  apiHost,
  apiBaseUrl,
  imgPlaceholder,
  translatables,
  defaultCoordinates,

  languages,
  lang4contents,

  listOfPoiServices,
  listOfPoiCategories,
  listOfNoticeCategories,
  poiFilters,
  trailFilters,

  colors,
  noticeFiltersForFiltering,
  poiFiltersForFiltering,
  trailFiltersForFiltering,
  handleToast,
  handleAlert,
  handleConfirm,
  coordsRule,
  sometimesRule,
  getIcon,
  handleApiError,
  handleStoreError,
  timeAgo,
  getKeyByValue,
  getValueByKey,
  showCategory,
  setTranslatables,
  setOrder,
  query,
  months,

  getSeriesOf,
})
